import { GQLVehicleSearchResultsQuery } from "@src/graphql.apollo.generated";
import FavIcon from "@src/shared/favicon/Favicon";
import { graphql, WrapPageElementBrowserArgs } from "gatsby";
import * as React from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import { GQLAllFacetsFragment } from "./graphql.gatsby.generated";
import DisplayNameProvider from "./shared/context/displayname/DisplayNameProvider";
import LocationProvider from "./shared/context/LocationProvider";
import SearchFilterProvider from "./shared/context/searchfilter/SearchFilterProvider";
import SearchResultProvider from "./shared/context/searchresult/SearchResultProvider";
import SnackbarProvider from "./shared/context/snackbar/SnackbarProvider";
import theme from "./shared/theme/theme-porschebank";
import { ITheme } from "./shared/theme/types";

const GlobalStyle = createGlobalStyle<{ theme: ITheme }>`
    * {
        box-sizing: border-box;
    }


    body {
        margin: 0;
        font-family: ${({ theme }) => theme.fonts.markW1G};
        -webkit-text-size-adjust: none;
    }
    button {
        font-family: ${({ theme }) => theme.fonts.markW1G};
    }
    ::placeholder {
        color: ${({ theme }) => theme.colors.porscheBankBlue};
        opacity: 0.7;
    }

    #onetrust-banner-sdk {
        top: 0 !important;
        height: fit-content !important;
    }
`;

interface MaybeAllFacets {
    leaseme?: { allVehicles: { facets: GQLAllFacetsFragment } };
}

interface MaybeVehicleSearchResults {
    initialData?: GQLVehicleSearchResultsQuery;
}

export const wrapPageElement = ({ element, props: { data, pageContext, location } }: WrapPageElementBrowserArgs) => {
    const facets = (data as MaybeAllFacets).leaseme?.allVehicles.facets;
    const initialData = (pageContext as MaybeVehicleSearchResults).initialData;

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <FavIcon />
            <DisplayNameProvider facets={facets}>
                <SearchFilterProvider location={location} facets={facets}>
                    <SearchResultProvider initialData={initialData}>
                        <LocationProvider location={location}>
                            <SnackbarProvider>{element}</SnackbarProvider>
                        </LocationProvider>
                    </SearchResultProvider>
                </SearchFilterProvider>
            </DisplayNameProvider>
        </ThemeProvider>
    );
};

export const query = graphql`
    fragment AllFacets on LeaseMe_VehicleFacetsType {
        brands {
            name
            slug
            count
        }
        modelGroups {
            name
            slug
            count
            brandSlug
        }
        bodyTypes {
            name
            slug
            count
        }
        fuelTypes {
            name
            slug
            count
        }
        provinces {
            name
            slug
            count
        }
        colors {
            name
            slug
            count
        }
    }
`;
