import { Operation } from "@apollo/client";
import { GraphQLError, print } from "graphql";

/* eslint-disable no-console */
export function PrintErrorMsg(graphQLErrors: ReadonlyArray<GraphQLError> | undefined, operation: Operation) {
    if (graphQLErrors) {
        console.error(`\n\n\n
        \n##################################################
        \n#-------------- GRAPHQL ERROR -------------------#
        \n##################################################
        \nOPERATION
        \n${operation.operationName}
        \n--------------------------------------------------------
        \nQUERY
        \n${print(operation.query).replace(/\n/g, " ")}
        \n--------------------------------------------------------
        \nVARIABLES
        \n${JSON.stringify(operation.variables, null, 2)}
        \n--------------------------------------------------------
        \nERROR
        \n${JSON.stringify(graphQLErrors, null, 2)}
        \n##################################################
        \n#---------------- END ERROR ---------------------#
        \n##################################################
        `);
    }
}
