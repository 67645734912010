/* eslint-disable no-console */
export let domain = "localhost";

if (!process.env.DOMAIN) {
    console.error("Environment variable DOMAIN not set, defaulting to localhost");
} else {
    domain = process.env.DOMAIN;
}

export interface IConfig {
    apiUrl: string | undefined;
    webcodeLanguage: string;
    languages: string[];
    oneTrustCookieID: string | undefined;
    googleSiteVerificationContent: string | undefined;
    scope: { domain: string };
    googleMapsApiKey: string | undefined;
    hasDealerMap: boolean;
    indexPrefix: string;
    hasCredit: boolean;
    hasCascoPid: boolean;
    hasDwa: boolean;
    damAllowedImageSizes: string;
    hasFinCalcLightbox: boolean;
    isCarhubActive: boolean;
    contactForm: string;
    showVehicleTypeBadge: boolean;
}

export const defaultConfig: IConfig = {
    apiUrl: process.env.BASE_URL,
    webcodeLanguage: "de",
    languages: ["de"],
    oneTrustCookieID: process.env.ONE_TRUST_COOKIE_ID,
    googleSiteVerificationContent: process.env.GOOGLE_SITE_VERIFICATION_CONTENT,
    scope: { domain: process.env.DOMAIN || domain },
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
    hasDealerMap: process.env.HAS_DEALER_MAP === "true",
    indexPrefix: process.env.INDEX_PREFIX || "leaseme",
    hasCredit: process.env.HAS_CREDIT === "true",
    hasCascoPid: process.env.HAS_CASCO_PID === "true",
    hasDwa: process.env.HAS_DWA === "true",
    damAllowedImageSizes: process.env.DAM_ALLOWED_IMAGE_SIZES ?? "",
    hasFinCalcLightbox: process.env.HAS_FINCALC_LIGHTBOX === "true",
    isCarhubActive: process.env.IS_CARHUB_ACTIVE === "true",
    contactForm: process.env.CONTACT_FORM ?? "",
    showVehicleTypeBadge: false,
};

export const getConfig = <K extends keyof IConfig>(key: K): IConfig[K] => {
    const c: IConfig = defaultConfig;
    return c[key];
};
