import { GQLFacetsFragment, GQLVehicleSearchResultVehicleFragment } from "@src/graphql.apollo.generated";
import * as React from "react";

export interface ISearchResultContext {
    currentPage: number;
    totalPages: number;
    totalCount: number;
    vehicles?: GQLVehicleSearchResultVehicleFragment[];
    facets?: GQLFacetsFragment | null;
    loading: boolean;
}

const SearchResultContext = React.createContext<ISearchResultContext>({
    currentPage: 0,
    totalPages: 0,
    totalCount: 0,
    loading: false,
});
export { SearchResultContext };
