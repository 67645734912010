import "@vivid/ff-mark-fonts";
import "@vivid/roboto-font";

import { ApolloProvider } from "@apollo/client";
import { getConfig } from "@src/config";
import { numeralLocaleDe, numeralLocaleHu } from "@src/numeral";
import CustomIntlProvider, { getLanguagefromDomain } from "@src/skeleton/intlProvider/CustomIntlProvider";
import { WrapRootElementBrowserArgs } from "gatsby";
import numeral from "numeral";
import * as React from "react";
import Helmet from "react-helmet";

import { publicClient } from "./shared/apollo/client";

numeral.register("locale", "de", numeralLocaleDe);
numeral.register("locale", "hu", numeralLocaleHu);

if (getConfig("scope").domain === "car4me.porschefinance.hu") {
    numeral.locale("hu");
} else {
    numeral.locale("de");
}

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
    const oneTrustCookieID = getConfig("oneTrustCookieID");
    const lang = getLanguagefromDomain(getConfig("scope").domain);

    return (
        <CustomIntlProvider>
            <ApolloProvider client={publicClient}>
                {element}
                {!!oneTrustCookieID && typeof window !== "undefined" && (
                    <Helmet>
                        <script
                            src="https://phs.my.onetrust.eu/cdn/cookies/scripttemplates/otSDKStub.js"
                            data-document-language="true"
                            type="text/javascript"
                            data-domain-script={oneTrustCookieID}
                            defer
                        />
                    </Helmet>
                )}
                <Helmet
                    htmlAttributes={{
                        lang,
                    }}
                />
                <Helmet>
                    <meta name="google-site-verification" content={getConfig("googleSiteVerificationContent")} />
                </Helmet>
            </ApolloProvider>
        </CustomIntlProvider>
    );
};
