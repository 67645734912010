import { IFilter, IOptionalFilter, OptionalFilter, optionalFilters } from "@src/shared/context/searchfilter/IFilter";

function getOptionalFilters(filter: IFilter): IOptionalFilter[] {
    const usedOptionalFilters: IOptionalFilter[] = [];

    Object.keys(filter)
        .filter((key) => optionalFilters.includes(key))
        .forEach((filterKey: OptionalFilter) => {
            const filterValue = filter[filterKey];

            if (!filterValue) {
                return;
            }

            if (Array.isArray(filterValue)) {
                usedOptionalFilters.push(
                    ...filterValue.map((value) => ({
                        filter: filterKey,
                        value,
                    })),
                );
            } else {
                usedOptionalFilters.push({
                    filter: filterKey,
                    value: String(filterValue),
                });
            }
        });

    return usedOptionalFilters;
}

export default getOptionalFilters;
