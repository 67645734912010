import { WindowLocation } from "@reach/router";
import { getConfig } from "@src/config";
import { GQLFinanceType } from "@src/graphql.apollo.generated";
import * as queryString from "query-string";

import { getDefaultFilter, IFilter } from "./IFilter";

// TODO map for parameter priority, e.g. /brand/modelGroup > /brand/location

interface TrackingParameters {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_term?: string;
    gclid?: string;
    fbclid?: string;
    msclkid?: string;
}

type TrackingParametersKeys = keyof TrackingParameters;

export const generateUrlFromFilter = (location: WindowLocation, filter: IFilter, view: "vehicles" | "models" | "home", asLandingPage = true) => {
    const trackingParameters = parseTrackingParams(location.search);
    const parameters = removeDefaultFilterValues(filter);

    const pathSegments: string[] = [];

    if (!filter.brands && filter.modelGroups) {
        delete parameters.modelGroups;
    }

    if (view === "vehicles" || view === "models") {
        if (view === "models") {
            pathSegments.push("m");
        }

        if (filter.financeType === GQLFinanceType.leasing) {
            pathSegments.push("leasing");
            delete parameters.financeType;
        } else if (filter.financeType === GQLFinanceType.credit) {
            pathSegments.push("kredit");
            delete parameters.financeType;
            delete parameters.kmPerYear;
        } else if (filter.financeType === GQLFinanceType.openEndLeasing) {
            pathSegments.push("openEndLeasing");
            delete parameters.financeType;
        } else if (filter.financeType === GQLFinanceType.closedEndLeasing) {
            pathSegments.push("closedEndLeasing");
            delete parameters.financeType;
        }

        if (asLandingPage) {
            if (filter.brands && filter.brands.length === 1) {
                pathSegments.push(filter.brands[0]);
                delete parameters.brands;

                if (filter.modelGroups && filter.modelGroups.length === 1) {
                    pathSegments.push(filter.modelGroups[0]);
                    delete parameters.modelGroups;
                }
            } else if (filter.provinces && filter.provinces.length === 1) {
                pathSegments.push(filter.provinces[0]);
                delete parameters.provinces;
            } else if (filter.bodyTypes && filter.bodyTypes.length === 1) {
                pathSegments.push(filter.bodyTypes[0]);
                delete parameters.bodyTypes;
            }
        }
    }

    let path = "/";

    if (pathSegments.length > 0) {
        path += pathSegments.join("/");
    }

    if (view !== "home") {
        path += "/";
    }

    const search = queryString.stringify({ ...parameters, ...trackingParameters });

    return `${path}${search ? "?" + search : ""}`;
};

function removeDefaultFilterValues(filter: IFilter): Partial<IFilter> {
    const result: Partial<IFilter> = {};

    const defaultFilter = getDefaultFilter(getConfig("scope").domain);

    Object.keys(filter).forEach(<FilterKey extends keyof IFilter>(filterKey: FilterKey) => {
        if (filter[filterKey] !== defaultFilter[filterKey]) {
            result[filterKey] = filter[filterKey];
        }
    });

    return result;
}

export function parseTrackingParams(trackingParams: string) {
    const trackingKeys: TrackingParametersKeys[] = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_content",
        "utm_term",
        "gclid",
        "fbclid",
        "msclkid",
    ];
    const result: Partial<TrackingParameters> = {};

    const params = new URLSearchParams(trackingParams);

    for (const trackingKey of trackingKeys) {
        const param = params.get(trackingKey);

        if (param) {
            result[trackingKey] = param;
        }
    }

    return result;
}
