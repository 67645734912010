import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import fetch from "isomorphic-fetch";

import { PrintErrorMsg } from "./errorMsg";

const publicHttpLinkLeaseMe = new HttpLink({
    // We use different URIs for SSR and for the client. For SSR, we can use the internal URL of the API,
    // as we're in the OpenShift network. For the client we need to use the public URL of the API.
    uri: typeof window === "object" ? `${process.env.EXTERNAL_API_URL}/graphql` : `${process.env.API_URL}/graphql`,
    fetch,
});

const errorLink = onError(({ graphQLErrors, operation }) => {
    PrintErrorMsg(graphQLErrors, operation);
});

export const publicClient = new ApolloClient({
    link: from([errorLink, publicHttpLinkLeaseMe]),
    cache: new InMemoryCache(),
});

const privateHttpLinkLeaseMe = new HttpLink({
    // We use different URIs for SSR and for the client. For SSR, we can use the internal URL of the API,
    // as we're in the OpenShift network. For the client we need to use the public URL of the API.
    uri: typeof window === "object" ? `${process.env.EXTERNAL_API_URL}/graphql` : `${process.env.API_URL}/graphql`,
    fetch,
    headers: {
        Authorization: `Basic ${Buffer.from(`vivid:${process.env.API_PASSWORD}`).toString("base64")}`,
    },
});

export const privateClient = new ApolloClient({
    link: from([errorLink, privateHttpLinkLeaseMe]),
    cache: new InMemoryCache(),
});
