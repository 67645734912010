import { getConfig } from "@src/config";
import { GQLFinanceType } from "@src/graphql.apollo.generated";
import { IFilter } from "@src/shared/context/searchfilter/IFilter";

import { SearchEvent } from "./types";

const buildSearchEvent = (filter: IFilter, totalCount: number) => {
    const filters: SearchEvent["filters"] = [];

    if (filter.brands) {
        filters.push({ filterType: "Brands", filterValue: filter.brands });
    }

    if (filter.modelGroups) {
        filters.push({
            filterType: "Model groups",
            filterValue: filter.modelGroups,
        });
    }

    if (filter.colors) {
        filters.push({
            filterType: "Colors",
            filterValue: filter.colors,
        });
    }

    if (filter.minKm || filter.maxKm) {
        filters.push({
            filterType: "Mileage",
            filterRangeMin: filter.minKm || null,
            filterRangeMax: filter.maxKm || null,
        });
    }

    if (filter.minFirstRegistration || filter.maxFirstRegistration) {
        filters.push({
            filterType: "First registration",
            filterRangeMin: filter.minFirstRegistration || null,
            filterRangeMax: filter.maxFirstRegistration || null,
        });
    }

    if (filter.minPs || filter.maxPs) {
        filters.push({
            filterType: "Power",
            filterRangeMin: filter.minPs || null,
            filterRangeMax: filter.maxPs || null,
        });
    }

    if (filter.bodyTypes) {
        filters.push({
            filterType: "Body types",
            filterValue: filter.bodyTypes,
        });
    }

    if (filter.fuelTypes) {
        filters.push({
            filterType: "Fuel types",
            filterValue: filter.fuelTypes,
        });
    }

    if (filter.provinces) {
        filters.push({
            filterType: "Provinces",
            filterValue: filter.provinces,
        });
    }

    const event: SearchEvent = {
        event: getConfig("scope").domain === "lease-me.porschebank.at" ? "leaseme-search" : "car4me-search",
        listItems: totalCount,
        financingType:
            filter.financeType === GQLFinanceType.credit
                ? "Credit"
                : filter.financeType === GQLFinanceType.leasing
                ? "Leasing"
                : filter.financeType === GQLFinanceType.openEndLeasing
                ? "OpenEndLeasing"
                : "ClosedEndLeasing",
        financingBudget: filter.maximumRate,
        financingDuration: filter.financeRuntime,
        financingDownPayment: filter.downPayment,
        financingAnnualMileage: filter.kmPerYear,
        financingResidualValuePercent: 20,
        filters,
    };

    return event;
};

export default buildSearchEvent;
