import { useQuery } from "@apollo/client";
import { useLocation } from "@reach/router";
import { getConfig } from "@src/config";
import { GQLVehicleSearchResultsQuery, GQLVehicleSearchResultsQueryVariables } from "@src/graphql.apollo.generated";
import buildSearchEvent from "@src/shared/tracking/buildSearchEvent";
import pushToDataLayer from "@src/shared/tracking/pushToDataLayer";
import isHomePage from "@src/shared/utils/isHomePage";
import isLandingPage from "@src/shared/utils/isLandingPage";
import { VehicleSearchResultQuery } from "@src/skeleton/searchResults/vehicleSearchResults/VehicleSearchResult.gql";
import debounce from "lodash.debounce";
import * as React from "react";

import { IFilter } from "../searchfilter/IFilter";
import useSearchFilter from "../searchfilter/useSearchFilter";
import { SearchResultContext } from "./SearchResultContext";

export interface ISearchResultProviderProps {
    initialData?: GQLVehicleSearchResultsQuery;
}

const SearchResultProvider: React.FunctionComponent<ISearchResultProviderProps> = ({ children, initialData }) => {
    const { filter } = useSearchFilter();

    const { data, loading } = useQuery<GQLVehicleSearchResultsQuery, GQLVehicleSearchResultsQueryVariables>(VehicleSearchResultQuery, {
        variables: {
            page: filter.page,
            size: filter.size,
            maximumRate: filter.maximumRate ?? undefined,
            financeType: filter.financeType,
            financeRuntime: filter.financeRuntime,
            yearlyMileage: filter.kmPerYear,
            downPayment: filter.downPayment ?? undefined,
            brands: filter.brands,
            modelGroups: filter.modelGroups,
            colors: filter.colors,
            minMileage: filter.minKm,
            maxMileage: filter.maxKm,
            minFirstRegistration: filter.minFirstRegistration,
            maxFirstRegistration: filter.maxFirstRegistration,
            minPS: filter.minPs,
            maxPS: filter.maxPs,
            bodyTypes: filter.bodyTypes,
            fuelTypes: filter.fuelTypes,
            provinces: filter.provinces,
            sort: filter.sort,
            sortOrder: filter.sortOrder,
            leasemeScope: getConfig("scope"),
            carType: filter.carType,
            remainingCredit: filter.remainingCredit,
        },
    });

    const location = useLocation();
    const previousLocation = usePrevious(location);

    React.useEffect(() => {
        if (!data) {
            return;
        }

        const { totalCount } = data.vehicles;

        const onLandingPage = isLandingPage(location);
        const comingFromHome = previousLocation && isHomePage(previousLocation);

        pushGtmEvents({
            filter,
            totalCount,
            pushSearchEvent: !(onLandingPage && comingFromHome),
            pushSearchResultsEvent: onLandingPage,
        });
    }, [data, filter, location, previousLocation]);

    const currentData = data || initialData;

    return (
        <SearchResultContext.Provider
            value={{
                vehicles: currentData?.vehicles.nodes,
                facets: currentData?.vehicles.facets,
                currentPage: filter.page,
                totalPages: currentData?.vehicles.totalPages ?? 0,
                totalCount: currentData?.vehicles.totalCount ?? 0,
                loading: typeof window === "undefined" ? false : loading,
            }}
        >
            {children}
        </SearchResultContext.Provider>
    );
};

export default SearchResultProvider;

const pushGtmEvents = debounce(
    ({
        filter,
        totalCount,
        pushSearchEvent,
        pushSearchResultsEvent,
    }: {
        filter: IFilter;
        totalCount: number;
        pushSearchEvent: boolean;
        pushSearchResultsEvent: boolean;
    }) => {
        if (pushSearchEvent) {
            pushToDataLayer(buildSearchEvent(filter, totalCount));
        }

        if (pushSearchResultsEvent) {
            pushToDataLayer({ event: "leaseme-search-results" });
        }
    },
    500,
    { leading: true, trailing: false },
);

const usePrevious = <T,>(value: T): T | undefined => {
    const ref = React.useRef<T>();

    React.useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};
