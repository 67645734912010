import { WindowLocation } from "@reach/router";
import { getConfig } from "@src/config";
import { GQLFinanceType, GQLSortOrder, GQLVehicleSort } from "@src/graphql.apollo.generated";
import { GQLAllFacetsFragment, Maybe } from "@src/graphql.gatsby.generated";
import { sliderMinMaxValues } from "@src/shared/searchResults/searchfilter/filter/sliderfilter/sliderMinMaxValues";
import * as queryString from "querystring";

import { getDefaultFilter, IFilter } from "./IFilter";
import { firstRegistrationYearOptions, getFinanceRuntimeValues, getKmPerYearOptions, kmFilterOptions, psFilterOptions } from "./Options";

export const parseFilterFromUrl = (location: WindowLocation, facets?: Maybe<GQLAllFacetsFragment>): IFilter => {
    const defaultFilter = getDefaultFilter(getConfig("scope").domain);

    const filter = { ...defaultFilter };

    const financeRuntimeOptions = getFinanceRuntimeValues(getConfig("scope").domain, filter);

    const searchInFacets = (pathSegment: string) => {
        const brand = facets?.brands?.find((brand) => brand.slug === pathSegment);

        if (brand) {
            filter.brands = [brand.slug];
            return;
        }

        const modelGroup = facets?.modelGroups?.find((modelGroup) => modelGroup.slug === pathSegment);

        if (modelGroup) {
            filter.modelGroups = [modelGroup.slug];
            return;
        }

        const bodyType = facets?.bodyTypes?.find((bodyType) => bodyType.slug === pathSegment);

        if (bodyType) {
            filter.bodyTypes = [bodyType.slug];
            return;
        }

        const province = facets?.provinces?.find((province) => province.slug === pathSegment);

        if (province) {
            filter.provinces = [province.slug];
            return;
        }
    };

    const pathSegments = location.pathname.split("/").filter((segment) => segment.length > 0);

    for (const pathSegment of pathSegments) {
        switch (pathSegment) {
            case "leasing":
                filter.financeType = GQLFinanceType.leasing;
                break;

            case "kredit":
                filter.financeType = GQLFinanceType.credit;
                break;

            case "openEndLeasing":
                filter.financeType = GQLFinanceType.openEndLeasing;
                break;

            case "closedEndLeasing":
                filter.financeType = GQLFinanceType.closedEndLeasing;
                break;

            case "ohne-anzahlung":
                filter.downPayment = 0;
                break;

            case "guenstige-autos":
                filter.maximumRate = 100;
                break;

            default:
                searchInFacets(pathSegment);
        }
    }

    const search = location.search; // ?fuel=Diesel

    const dynamicParameter = queryString.parse(search.substr(1));

    //Page
    if (dynamicParameter.page && !Array.isArray(dynamicParameter.page)) {
        filter.page = limitValue(parseInt(dynamicParameter.page), 1, 10000);
    }

    //Size
    if (dynamicParameter.size && !Array.isArray(dynamicParameter.size)) {
        filter.size = limitValue(parseInt(dynamicParameter.size), 1, 10000);
    }

    //Maximum Rate
    if (dynamicParameter.maximumRate && !Array.isArray(dynamicParameter.maximumRate)) {
        filter.maximumRate = limitValue(
            parseInt(dynamicParameter.maximumRate),
            sliderMinMaxValues(getConfig("scope").domain).min,
            sliderMinMaxValues(getConfig("scope").domain).max,
        );
    }

    if (dynamicParameter.carType && !Array.isArray(dynamicParameter.carType)) {
        filter.carType = dynamicParameter.carType;
    }

    //Finance Type
    if (
        dynamicParameter.financeType &&
        (dynamicParameter.financeType === GQLFinanceType.leasing ||
            dynamicParameter.financeType === GQLFinanceType.credit ||
            dynamicParameter.financeType === GQLFinanceType.openEndLeasing ||
            dynamicParameter.financeType === GQLFinanceType.closedEndLeasing)
    ) {
        filter.financeType = dynamicParameter.financeType;
    }

    //Finance Runtime
    if (dynamicParameter.financeRuntime && !Array.isArray(dynamicParameter.financeRuntime)) {
        filter.financeRuntime = limitValueToSteps(
            parseInt(dynamicParameter.financeRuntime),
            financeRuntimeOptions.map((options) => options.value),
        );
    }

    //Remaining Credit
    if (dynamicParameter.remainingCredit && !Array.isArray(dynamicParameter.remainingCredit)) {
        filter.remainingCredit = limitValue(parseInt(dynamicParameter.remainingCredit), 0, 50);
    }

    // KM per Year
    if (dynamicParameter.kmPerYear && !Array.isArray(dynamicParameter.kmPerYear)) {
        const kmPerYearOptions = getKmPerYearOptions(getConfig("scope").domain);
        filter.kmPerYear = limitValueToSteps(
            parseInt(dynamicParameter.kmPerYear),
            kmPerYearOptions.map((options) => options.value),
        );
    }

    // Down payment
    if (dynamicParameter.downPayment && !Array.isArray(dynamicParameter.downPayment)) {
        filter.downPayment = limitValue(
            parseInt(dynamicParameter.downPayment),
            0,
            9999999999, // limit the downpayment with 10 characters
        );
    }

    if (dynamicParameter.brands) {
        if (Array.isArray(dynamicParameter.brands)) {
            filter.brands = dynamicParameter.brands;
        } else {
            filter.brands = [dynamicParameter.brands];
        }
    }

    if (dynamicParameter.modelGroups) {
        if (Array.isArray(dynamicParameter.modelGroups)) {
            filter.modelGroups = dynamicParameter.modelGroups;
        } else {
            filter.modelGroups = [dynamicParameter.modelGroups];
        }
    }

    // Colors
    if (dynamicParameter.colors) {
        if (Array.isArray(dynamicParameter.colors)) {
            filter.colors = dynamicParameter.colors;
        } else {
            filter.colors = [dynamicParameter.colors];
        }
    }

    // Min Mileage
    if (dynamicParameter.minKm && !Array.isArray(dynamicParameter.minKm)) {
        filter.minKm = limitValueToSteps(
            parseInt(dynamicParameter.minKm),
            kmFilterOptions(getConfig("scope").domain).map((options) => options.value),
        );
    }

    // Max Mileage
    if (dynamicParameter.maxKm && !Array.isArray(dynamicParameter.maxKm)) {
        filter.maxKm = limitValueToSteps(
            parseInt(dynamicParameter.maxKm),
            kmFilterOptions(getConfig("scope").domain, filter.minKm).map((options) => options.value),
        );
    }

    // Min First Registration
    if (dynamicParameter.minFirstRegistration && !Array.isArray(dynamicParameter.minFirstRegistration)) {
        filter.minFirstRegistration = limitValueToSteps(
            parseInt(dynamicParameter.minFirstRegistration),
            firstRegistrationYearOptions().map((options) => options.value),
        );
    }

    // Max First Registration
    if (dynamicParameter.maxFirstRegistration && !Array.isArray(dynamicParameter.maxFirstRegistration)) {
        filter.maxFirstRegistration = limitValueToSteps(
            parseInt(dynamicParameter.maxFirstRegistration),
            firstRegistrationYearOptions(filter.minFirstRegistration).map((options) => options.value),
        );
    }

    // Min PS
    if (dynamicParameter.minPs && !Array.isArray(dynamicParameter.minPs)) {
        filter.minPs = limitValueToSteps(
            parseInt(dynamicParameter.minPs),
            psFilterOptions().map((options) => options.value),
        );
    }

    // Max PS
    if (dynamicParameter.maxPs && !Array.isArray(dynamicParameter.maxPs)) {
        filter.maxPs = limitValueToSteps(
            parseInt(dynamicParameter.maxPs),
            psFilterOptions(filter.minPs).map((options) => options.value),
        );
    }

    if (dynamicParameter.bodyTypes) {
        if (Array.isArray(dynamicParameter.bodyTypes)) {
            filter.bodyTypes = dynamicParameter.bodyTypes;
        } else {
            filter.bodyTypes = [dynamicParameter.bodyTypes];
        }
    }

    if (dynamicParameter.fuelTypes) {
        if (Array.isArray(dynamicParameter.fuelTypes)) {
            filter.fuelTypes = dynamicParameter.fuelTypes;
        } else {
            filter.fuelTypes = [dynamicParameter.fuelTypes];
        }
    }

    if (dynamicParameter.provinces) {
        if (Array.isArray(dynamicParameter.provinces)) {
            filter.provinces = dynamicParameter.provinces;
        } else {
            filter.provinces = [dynamicParameter.provinces];
        }
    }

    if (dynamicParameter.sort) {
        filter.sort = dynamicParameter.sort as GQLVehicleSort;
    }

    if (dynamicParameter.sortOrder) {
        filter.sortOrder = dynamicParameter.sortOrder as GQLSortOrder;
    }

    return filter;
};

const limitValue = (value: number, minValue: number, maxValue: number) => {
    return Math.min(maxValue, Math.max(minValue, value));
};

const limitValueToSteps = (value: number, allowedSteps: number[]) => {
    if (allowedSteps.length <= 0) {
        return value;
    }

    let bestMatchingValue = allowedSteps[0];

    for (const allowedStep of allowedSteps) {
        if (allowedStep === value) {
            return value;
        }
        const bestMatchingValueDifference = Math.abs(bestMatchingValue - value);
        const currentValueDifference = Math.abs(allowedStep - value);
        if (currentValueDifference < bestMatchingValueDifference) {
            bestMatchingValue = allowedStep;
        }
    }
    return bestMatchingValue;
};
