import { WindowLocation } from "@reach/router";
import * as React from "react";

import { LocationContext } from "./LocationContext";

export interface ILocationProviderProps {
    location: WindowLocation;
}

const LocationProvider: React.FunctionComponent<ILocationProviderProps> = ({ location, children }) => {
    return (
        <LocationContext.Provider
            value={{
                location,
            }}
        >
            {children}
        </LocationContext.Provider>
    );
};

export default LocationProvider;
