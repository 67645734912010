import numeral from "numeral";

function getFormattedNumber(value: number | null | undefined) {
    if (value === null || value === undefined) {
        return "-";
    }

    if (value % 1 != 0) {
        return numeral(value).format("0,0.00");
    }

    return numeral(value).format("0,0.[00]");
}

export default getFormattedNumber;
