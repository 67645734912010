import { getConfig } from "@src/config";
import * as React from "react";
import { IntlProvider } from "react-intl";

import leaseMe_messages_bg from "../../../lang/bg.json";
import leaseMe_messages_de from "../../../lang/de.json";
import leaseMe_messages_hu from "../../../lang/hu.json";
import leaseMe_messages_ro from "../../../lang/ro.json";
import leaseMe_messages_sl from "../../../lang/sl.json";

function loadLocaleData(locale: string) {
    switch (locale) {
        case "bg":
            return leaseMe_messages_bg;
        case "ro":
            return leaseMe_messages_ro;
        case "sl":
            return leaseMe_messages_sl;
        case "hu":
            return leaseMe_messages_hu;
        default:
            return leaseMe_messages_de;
    }
}

export function getCurrencySignFromDomain(domain: string) {
    switch (domain) {
        case "car4me.porschefinance.hu":
            return "Ft";
        case "car4me.bg":
            return "лв.";
        case "car4me.ro":
        case "car4me.si":
        default:
            return "€";
    }
}

export function getCountryCodeFromScope() {
    switch (getConfig("scope").domain) {
        case "car4me.bg":
            return "BG";
        case "car4me.si":
            return "SI";
        case "car4me.porschefinance.hu":
            return "HU";
        default:
            return "AT";
    }
}

const domainLanguageMap: { [k: string]: string } = {
    "lease-me.porschebank.at": "de",
    "car4me.bg": "bg",
    "car4me.ro": "ro",
    "car4me.si": "sl",
    "car4me.porschefinance.hu": "hu",
};

export function getLanguagefromDomain(domain: string): string {
    return domainLanguageMap[domain] || "de";
}

const language = getLanguagefromDomain(getConfig("scope").domain);
const languageMessage = loadLocaleData(language);

const CustomIntlProvider: React.FunctionComponent = ({ children }) => {
    return (
        <IntlProvider locale={language} messages={languageMessage}>
            {children}
        </IntlProvider>
    );
};

export default CustomIntlProvider;
