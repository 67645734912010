export enum ThemeName {
    PorscheBank = "PorscheBank",
}

export interface ITheme {
    name: ThemeName;
    boxShadow: string;
    fonts: {
        roboto: string;
        markW1G: string;
    };
    colors: {
        black: string;
        gray1: string;
        gray2: string;
        gray3: string;
        gray4: string;
        gray5: string;
        gray6: string;
        white: string;
        borderGray: string;
        blackWithAlpha: string;
        porscheBankBlue: string;
        porscheBankRed: string;
        darkRed: string;
        backgroundLightGray: string;
        borderLightGray: string;
        typo: string;
        lightGray: string;
        gray: string;
        iconGray: string;
        facebook: string;
        twitter: string;
        whatsApp: string;
        viber: string;
        error: string;
        success: string;
        info: string;
    };
    breakpoints: {
        tn: {
            mediaquery: string;
            value: number;
        };
        sm: {
            mediaquery: string;
            value: number;
        };
        md: {
            mediaquery: string;
            value: number;
        };
        lg: {
            mediaquery: string;
            value: number;
        };
        lg2: {
            mediaquery: string;
            value: number;
        };
        xl: {
            mediaquery: string;
            value: number;
        };
        xxl: {
            mediaquery: string;
            value: number;
        };
    };
    spacings: {
        spacing050: 5;
        spacing100: 10;
        spacing200: 20;
        spacing400: 40;
        spacing600: 60;
    };
}
