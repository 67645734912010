import * as React from "react";

export enum SnackbarType {
    info = "info",
    error = "error",
    success = "success",
}

export interface ISnackbar {
    type: SnackbarType;
    message: string;
}
interface ISnackbarContext {
    current: ISnackbar | null;
    showSnackbar: (type: SnackbarType, message: string) => void;
}

export const SnackbarContext = React.createContext<ISnackbarContext>({
    current: null,
    showSnackbar: () => {},
});
