const minMaxValues: {
    [key: string]: { min: number; max: number };
} = {
    "car4me.bg": { min: 200, max: 1200 },
    "car4me.ro": { min: 50, max: 1000 },
    "car4me.si": { min: 20, max: 2000 },
    "car4me.porschefinance.hu": { min: 30000, max: 300000 },
    "lease-me.porschebank.at": { min: 30, max: 1500 },
};

export function sliderMinMaxValues(scopeDomain: string) {
    return minMaxValues[scopeDomain];
}
