export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type GQLAttachedDocumentInput = {
  type: Scalars['String'];
  id?: Maybe<Scalars['String']>;
};

export type GQLAvailability = {
  __typename?: 'Availability';
  deliveryDate: Maybe<Scalars['String']>;
  available: Maybe<Scalars['Boolean']>;
};

export type GQLBodyColor = {
  __typename?: 'BodyColor';
  key: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  paint: Maybe<Scalars['String']>;
  paintCode: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
  metallic: Maybe<Scalars['String']>;
};

export type GQLBoniCheck = {
  __typename?: 'BoniCheck';
  groups: Maybe<Array<GQLGroupsEntity>>;
  control: GQLControlEntity;
  title: Scalars['String'];
  disclaimer: Scalars['String'];
  info: Scalars['String'];
  errors: Maybe<Array<GQLErrorEntity>>;
};

export type GQLBooleanFilter = {
  equal?: Maybe<Scalars['Boolean']>;
};

export type GQLBrandModelGroupFacetsType = {
  __typename?: 'BrandModelGroupFacetsType';
  brands: Maybe<Array<GQLDocumentFacet>>;
  modelGroups: Maybe<Array<GQLModelGroupFacet>>;
};

export type GQLBst = {
  __typename?: 'Bst';
  btrnr: Maybe<Scalars['Float']>;
  bstnr: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

export type GQLBuild = {
  __typename?: 'Build';
  id: Scalars['ID'];
  status: GQLJobStatus;
  name: Maybe<Scalars['String']>;
  trigger: Maybe<Scalars['String']>;
  startTime: Maybe<Scalars['DateTime']>;
  completionTime: Maybe<Scalars['DateTime']>;
  estimatedCompletionTime: Maybe<Scalars['DateTime']>;
};

export type GQLCampaign = {
  __typename?: 'Campaign';
  title: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
  textId: Maybe<Scalars['String']>;
  key: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  preisKorrektur: Maybe<Scalars['Float']>;
};

export enum GQLCarType {
  newCar = 'newCar',
  usedCar = 'usedCar'
}

export type GQLCascoFile = {
  __typename?: 'CascoFile';
  id: Scalars['ID'];
  file: GQLDamFile;
  scope: GQLContentScope;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type GQLContentScope = {
  __typename?: 'ContentScope';
  domain: Scalars['String'];
};

export type GQLContentScopeInput = {
  domain: Scalars['String'];
};

export type GQLControlEntity = {
  __typename?: 'ControlEntity';
  checkPossible: Scalars['Boolean'];
};

export type GQLCreateDamFolderInput = {
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type GQLCreateSchemeUrlInput = {
  url: Scalars['String'];
  active: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  schemeId: Scalars['Float'];
  schemeUrlVersion: Scalars['String'];
};

export type GQLDamFile = {
  __typename?: 'DamFile';
  id: Scalars['ID'];
  folder: Maybe<GQLDamFolder>;
  name: Scalars['String'];
  size: Scalars['Int'];
  mimetype: Scalars['String'];
  contentHash: Scalars['String'];
  title: Maybe<Scalars['String']>;
  altText: Maybe<Scalars['String']>;
  archived: Scalars['Boolean'];
  image: Maybe<GQLDamFileImage>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  fileUrl: Scalars['String'];
  duplicates: Array<GQLDamFile>;
  damPath: Scalars['String'];
};

export type GQLDamFileImage = {
  __typename?: 'DamFileImage';
  id: Scalars['ID'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  exif: Maybe<Scalars['JSONObject']>;
  dominantColor: Maybe<Scalars['String']>;
  cropArea: GQLImageCropArea;
  url: Maybe<Scalars['String']>;
};


export type GQLDamFileImageurlArgs = {
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type GQLDamFolder = {
  __typename?: 'DamFolder';
  id: Scalars['ID'];
  name: Scalars['String'];
  parent: Maybe<GQLDamFolder>;
  numberOfChildFolders: Scalars['Int'];
  numberOfFiles: Scalars['Int'];
  mpath: Array<Scalars['ID']>;
  archived: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  parents: Array<GQLDamFolder>;
};

export type GQLDamItem = GQLDamFile | GQLDamFolder;

export type GQLDamItemFilterInput = {
  searchText?: Maybe<Scalars['String']>;
  mimetypes?: Maybe<Array<Scalars['String']>>;
};

export type GQLDateFilter = {
  equal?: Maybe<Scalars['DateTime']>;
  lowerThan?: Maybe<Scalars['DateTime']>;
  greaterThan?: Maybe<Scalars['DateTime']>;
  lowerThanEqual?: Maybe<Scalars['DateTime']>;
  greaterThanEqual?: Maybe<Scalars['DateTime']>;
  notEqual?: Maybe<Scalars['DateTime']>;
};


export type GQLDealer = {
  __typename?: 'Dealer';
  stageimgUrl: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  street: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  zip: Maybe<Scalars['String']>;
  telephone: Maybe<Scalars['String']>;
  openingTimes: Maybe<Array<GQLOpeningTimes>>;
  country: Maybe<Scalars['String']>;
  bnr: Scalars['Float'];
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
};

export type GQLDocumentFacet = {
  __typename?: 'DocumentFacet';
  name: Scalars['String'];
  slug: Scalars['String'];
  count: Scalars['Int'];
};

export type GQLDocumentInterface = {
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type GQLEquipment = {
  __typename?: 'Equipment';
  code: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
  currency: Maybe<Scalars['String']>;
  priceGross: Maybe<Scalars['Int']>;
  priceNet: Maybe<Scalars['Int']>;
  specialOfferValue: Maybe<Scalars['Int']>;
  type: Maybe<Scalars['String']>;
  bankPromotion: Maybe<Scalars['Boolean']>;
  visibleInWeb: Maybe<Scalars['Boolean']>;
  equipmentCategory: Maybe<Scalars['String']>;
  visibleInVu: Maybe<Scalars['Boolean']>;
  novaRequired: Maybe<Scalars['Boolean']>;
};

export type GQLErrorEntity = {
  __typename?: 'ErrorEntity';
  field: Scalars['String'];
  description: Scalars['String'];
};

export type GQLFacetArguments = {
  __typename?: 'FacetArguments';
  brands: Maybe<Array<Scalars['String']>>;
  modelGroups: Maybe<Array<Scalars['String']>>;
  colors: Maybe<Array<Scalars['String']>>;
  mileage: Maybe<GQLRangeInputType>;
  firstRegistrationYear: Maybe<GQLRangeInputType>;
  powerPS: Maybe<GQLRangeInputType>;
  bodyTypes: Maybe<Array<Scalars['String']>>;
  fuelTypes: Maybe<Array<Scalars['String']>>;
  provinces: Maybe<Array<Scalars['String']>>;
  driveTypes: Maybe<Array<Scalars['String']>>;
  carType: Maybe<Scalars['String']>;
  maximumRate: Maybe<Scalars['Float']>;
  financeRuntime: Maybe<Scalars['Int']>;
  downPayment: Maybe<Scalars['Float']>;
  yearlyMileage: Maybe<Scalars['Int']>;
  financeType: Maybe<GQLFinanceType>;
  query: Maybe<Scalars['String']>;
  brand: Maybe<Scalars['String']>;
  sort: Maybe<GQLVehicleSort>;
  sortOrder: Maybe<GQLSortOrder>;
  remainingCredit: Maybe<Scalars['Int']>;
};

export type GQLFacetArgumentsInput = {
  maximumRate?: Maybe<Scalars['Float']>;
  financeRuntime?: Maybe<Scalars['Int']>;
  downPayment?: Maybe<Scalars['Float']>;
  yearlyMileage?: Maybe<Scalars['Int']>;
  financeType?: Maybe<GQLFinanceType>;
  query?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  sort?: Maybe<GQLVehicleSort>;
  sortOrder?: Maybe<GQLSortOrder>;
  remainingCredit?: Maybe<Scalars['Int']>;
};

export type GQLFileFilterInput = {
  searchText?: Maybe<Scalars['String']>;
  mimetypes?: Maybe<Array<Scalars['String']>>;
};

export type GQLFilenameInput = {
  name: Scalars['String'];
  folderId?: Maybe<Scalars['ID']>;
};

export type GQLFilenameResponse = {
  __typename?: 'FilenameResponse';
  name: Scalars['String'];
  folderId: Maybe<Scalars['ID']>;
  isOccupied: Scalars['Boolean'];
};

export type GQLFinanceCalculationInput = {
  financeRuntime: Scalars['Int'];
  downPayment: Scalars['Float'];
  yearlyMileage?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  residualCreditInPercent?: Maybe<Scalars['Int']>;
  bonusMalusLevel?: Maybe<Scalars['String']>;
  comprehensiveInsuranceType?: Maybe<Scalars['String']>;
  liabilityRate?: Maybe<Scalars['String']>;
  passengerRate?: Maybe<Scalars['String']>;
  legalProtectionRate?: Maybe<Scalars['String']>;
  alauvRate?: Maybe<Scalars['String']>;
};

export type GQLFinanceData = {
  __typename?: 'FinanceData';
  rate: Maybe<Scalars['Float']>;
  downPayment: Maybe<Scalars['Float']>;
  disclaimer: Maybe<Scalars['String']>;
  residualValue: Maybe<Scalars['Float']>;
  processingCostAmount: Maybe<Scalars['Float']>;
  interestRate: Maybe<Scalars['Float']>;
  effectivRate: Maybe<Scalars['Float']>;
  sumAllPayments: Maybe<Scalars['Float']>;
  financingSum: Maybe<Scalars['Float']>;
  interestRateEff: Maybe<Scalars['Float']>;
};

export type GQLFinanceDataInput = {
  rate: Scalars['Float'];
  cashBenefit: Scalars['Int'];
};

export type GQLFinanceInformation = {
  __typename?: 'FinanceInformation';
  productDisclaimer: Maybe<Scalars['String']>;
  globalDisclaimer: Maybe<Scalars['String']>;
  groups: Array<GQLFinanceInformationGroup>;
  errors: Maybe<Array<GQLFinanceInformationError>>;
};

export type GQLFinanceInformationError = {
  __typename?: 'FinanceInformationError';
  type: Scalars['String'];
  group: Maybe<Scalars['String']>;
  field: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
};

export type GQLFinanceInformationGroup = {
  __typename?: 'FinanceInformationGroup';
  label: Maybe<Scalars['String']>;
  disclaimer: Maybe<Scalars['String']>;
  parameters: Array<GQLFinanceInformationParameter>;
};

export type GQLFinanceInformationParameter = {
  __typename?: 'FinanceInformationParameter';
  id: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  selectType: Maybe<Scalars['String']>;
  unit: Maybe<Scalars['String']>;
  dataType: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
  options: Maybe<Array<GQLFinanceInformationParameterOption>>;
  defaultOption: Maybe<Scalars['String']>;
  help: Maybe<Scalars['String']>;
  bold: Maybe<Scalars['Boolean']>;
  length: Maybe<Scalars['String']>;
  error: Maybe<GQLFinanceInformationError>;
};

export type GQLFinanceInformationParameterOption = {
  __typename?: 'FinanceInformationParameterOption';
  value: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
};

export enum GQLFinanceType {
  leasing = 'leasing',
  credit = 'credit',
  openEndLeasing = 'openEndLeasing',
  closedEndLeasing = 'closedEndLeasing'
}

export enum GQLFocalPoint {
  SMART = 'SMART',
  CENTER = 'CENTER',
  NORTHWEST = 'NORTHWEST',
  NORTHEAST = 'NORTHEAST',
  SOUTHWEST = 'SOUTHWEST',
  SOUTHEAST = 'SOUTHEAST'
}

export type GQLFolderFilterInput = {
  searchText?: Maybe<Scalars['String']>;
};

export type GQLFormattedValue = {
  __typename?: 'FormattedValue';
  value: Maybe<Scalars['String']>;
  fmt: Maybe<Scalars['String']>;
  fmtWkz: Maybe<Scalars['String']>;
  wkz: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
};

export type GQLFuel = {
  __typename?: 'Fuel';
  typeKey: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  shortText: Maybe<Scalars['String']>;
  unit: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
  code: Maybe<Scalars['String']>;
  co2: Maybe<GQLFormattedValue>;
  nox: Maybe<GQLFormattedValue>;
  consumption: Maybe<GQLFormattedValue>;
};

export type GQLGroupsEntity = {
  __typename?: 'GroupsEntity';
  name: Scalars['String'];
  parameters: Maybe<Array<GQLParametersEntity>>;
};

export type GQLGroupsEntityResponse = {
  __typename?: 'GroupsEntityResponse';
  name: Scalars['String'];
  parameters: Maybe<Array<GQLParametersEntityResponse>>;
};

export type GQLImage = {
  __typename?: 'Image';
  number: Maybe<Scalars['Float']>;
  sortnr: Maybe<Scalars['Float']>;
  tmstmp: Maybe<Scalars['Float']>;
  uri: Maybe<Scalars['String']>;
};

export type GQLImageCropArea = {
  __typename?: 'ImageCropArea';
  focalPoint: GQLFocalPoint;
  width: Maybe<Scalars['Float']>;
  height: Maybe<Scalars['Float']>;
  x: Maybe<Scalars['Float']>;
  y: Maybe<Scalars['Float']>;
};

export type GQLImageCropAreaInput = {
  focalPoint: GQLFocalPoint;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};


export enum GQLJobStatus {
  pending = 'pending',
  active = 'active',
  succeeded = 'succeeded',
  failed = 'failed'
}

export type GQLLink = GQLDocumentInterface & {
  __typename?: 'Link';
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  content: Scalars['JSONObject'];
  createdAt: Scalars['DateTime'];
};

export type GQLLinkInput = {
  content: Scalars['JSONObject'];
};

export type GQLLocation = {
  __typename?: 'Location';
  dealerId: Maybe<Scalars['String']>;
  groupId: Maybe<Scalars['String']>;
  province: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['Float']>;
  longitude: Maybe<Scalars['Float']>;
};

export type GQLModelGroupFacet = {
  __typename?: 'ModelGroupFacet';
  name: Scalars['String'];
  slug: Scalars['String'];
  count: Scalars['Int'];
  powerPSRange: Maybe<GQLRangeType>;
  fuelTypes: Maybe<Array<GQLDocumentFacet>>;
  provinces: Maybe<Array<GQLDocumentFacet>>;
  firstRegistrationRange: Maybe<GQLRangeType>;
  mileageRange: Maybe<GQLRangeType>;
  rateRange: Maybe<GQLRangeType>;
  image: Maybe<Scalars['String']>;
  brand: Maybe<Scalars['String']>;
  brandSlug: Maybe<Scalars['String']>;
};

export type GQLMovePageTreeNodesByNeighbourInput = {
  parentId?: Maybe<Scalars['String']>;
  afterId?: Maybe<Scalars['String']>;
  beforeId?: Maybe<Scalars['String']>;
};

export type GQLMovePageTreeNodesByPosInput = {
  parentId?: Maybe<Scalars['String']>;
  pos: Scalars['Int'];
};

export type GQLMutation = {
  __typename?: 'Mutation';
  createGatsbyBuild: Maybe<Scalars['Boolean']>;
  switchIndexAlias: Scalars['Boolean'];
  updateScheme: GQLScheme;
  create: GQLSchemeUrl;
  updateSchemeUrl: GQLSchemeUrl;
  saveLink: GQLLink;
  savePage: GQLPage;
  updatePageTreeNode: GQLPageTreeNode;
  deletePageTreeNode: Scalars['Boolean'];
  updatePageTreeNodeVisibility: GQLPageTreeNode;
  updatePageTreeNodeSlug: GQLPageTreeNode;
  movePageTreeNodesByPos: Array<GQLPageTreeNode>;
  movePageTreeNodesByNeighbour: Array<GQLPageTreeNode>;
  updatePageTreeNodeCategory: GQLPageTreeNode;
  createPageTreeNode: GQLPageTreeNode;
  createRedirect: GQLRedirect;
  updateRedirect: GQLRedirect;
  updateRedirectActiveness: GQLRedirect;
  deleteRedirect: Scalars['Boolean'];
  updateDamFile: GQLDamFile;
  moveDamFiles: Array<GQLDamFile>;
  archiveDamFile: GQLDamFile;
  restoreDamFile: GQLDamFile;
  deleteDamFile: Scalars['Boolean'];
  createDamFolder: GQLDamFolder;
  updateDamFolder: GQLDamFolder;
  moveDamFolders: Array<GQLDamFolder>;
  deleteDamFolder: Scalars['Boolean'];
  updateCascoFile: GQLCascoFile;
};


export type GQLMutationcreateGatsbyBuildArgs = {
  scope: GQLContentScopeInput;
};


export type GQLMutationswitchIndexAliasArgs = {
  scope: GQLContentScopeInput;
  schemeUrlVersion?: Maybe<Scalars['String']>;
};


export type GQLMutationupdateSchemeArgs = {
  id: Scalars['Int'];
  input: GQLUpdateSchemeInput;
};


export type GQLMutationcreateArgs = {
  data: GQLCreateSchemeUrlInput;
};


export type GQLMutationupdateSchemeUrlArgs = {
  id: Scalars['Int'];
  input: GQLUpdateSchemeUrlInput;
};


export type GQLMutationsaveLinkArgs = {
  linkId: Scalars['ID'];
  input: GQLLinkInput;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  attachedPageTreeNodeId?: Maybe<Scalars['ID']>;
};


export type GQLMutationsavePageArgs = {
  pageId: Scalars['ID'];
  input: GQLPageInput;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  attachedPageTreeNodeId?: Maybe<Scalars['ID']>;
};


export type GQLMutationupdatePageTreeNodeArgs = {
  id: Scalars['ID'];
  input: GQLPageTreeNodeUpdateInput;
};


export type GQLMutationdeletePageTreeNodeArgs = {
  id: Scalars['ID'];
};


export type GQLMutationupdatePageTreeNodeVisibilityArgs = {
  id: Scalars['ID'];
  input: GQLPageTreeNodeUpdateVisibilityInput;
};


export type GQLMutationupdatePageTreeNodeSlugArgs = {
  id: Scalars['ID'];
  slug: Scalars['String'];
};


export type GQLMutationmovePageTreeNodesByPosArgs = {
  ids: Array<Scalars['ID']>;
  input: GQLMovePageTreeNodesByPosInput;
};


export type GQLMutationmovePageTreeNodesByNeighbourArgs = {
  ids: Array<Scalars['ID']>;
  input: GQLMovePageTreeNodesByNeighbourInput;
};


export type GQLMutationupdatePageTreeNodeCategoryArgs = {
  id: Scalars['ID'];
  category: Scalars['String'];
};


export type GQLMutationcreatePageTreeNodeArgs = {
  input: GQLPageTreeNodeCreateInput;
  scope: GQLPageTreeNodeScopeInput;
  category: Scalars['String'];
};


export type GQLMutationcreateRedirectArgs = {
  scope?: GQLRedirectScopeInput;
  input: GQLRedirectInput;
};


export type GQLMutationupdateRedirectArgs = {
  id: Scalars['ID'];
  input: GQLRedirectInput;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
};


export type GQLMutationupdateRedirectActivenessArgs = {
  id: Scalars['ID'];
  input: GQLRedirectUpdateActivenessInput;
};


export type GQLMutationdeleteRedirectArgs = {
  id: Scalars['ID'];
};


export type GQLMutationupdateDamFileArgs = {
  id: Scalars['ID'];
  input: GQLUpdateDamFileInput;
};


export type GQLMutationmoveDamFilesArgs = {
  fileIds: Array<Scalars['ID']>;
  targetFolderId?: Maybe<Scalars['ID']>;
};


export type GQLMutationarchiveDamFileArgs = {
  id: Scalars['ID'];
};


export type GQLMutationrestoreDamFileArgs = {
  id: Scalars['ID'];
};


export type GQLMutationdeleteDamFileArgs = {
  id: Scalars['ID'];
};


export type GQLMutationcreateDamFolderArgs = {
  input: GQLCreateDamFolderInput;
};


export type GQLMutationupdateDamFolderArgs = {
  id: Scalars['ID'];
  input: GQLUpdateDamFolderInput;
};


export type GQLMutationmoveDamFoldersArgs = {
  folderIds: Array<Scalars['ID']>;
  targetFolderId?: Maybe<Scalars['ID']>;
};


export type GQLMutationdeleteDamFolderArgs = {
  id: Scalars['ID'];
};


export type GQLMutationupdateCascoFileArgs = {
  scope: GQLContentScopeInput;
  input: GQLUpdateCascoFileInput;
};

export type GQLOffer = {
  __typename?: 'Offer';
  type: Scalars['String'];
  duration: Scalars['Float'];
  mileage: Maybe<Scalars['Float']>;
  minRate: Maybe<GQLRate>;
  maxRate: Maybe<GQLRate>;
};

export type GQLOpeningTimes = {
  __typename?: 'OpeningTimes';
  title: Maybe<Scalars['String']>;
  mon: Maybe<Array<Scalars['String']>>;
  tue: Maybe<Array<Scalars['String']>>;
  wed: Maybe<Array<Scalars['String']>>;
  thu: Maybe<Array<Scalars['String']>>;
  fri: Maybe<Array<Scalars['String']>>;
  sat: Maybe<Array<Scalars['String']>>;
  sun: Maybe<Array<Scalars['String']>>;
};

export type GQLPage = GQLDocumentInterface & {
  __typename?: 'Page';
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  content: Scalars['JSONObject'];
  seo: Scalars['JSONObject'];
  stage: Scalars['JSONObject'];
  createdAt: Scalars['DateTime'];
  pageTreeNode: Maybe<GQLPageTreeNode>;
};

export type GQLPageContentUnion = GQLPage | GQLLink;

export type GQLPageInput = {
  content: Scalars['JSONObject'];
  stage: Scalars['JSONObject'];
  seo: Scalars['JSONObject'];
};

export type GQLPageTreeNode = {
  __typename?: 'PageTreeNode';
  id: Scalars['ID'];
  parentId: Maybe<Scalars['String']>;
  pos: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  visibility: GQLPageTreeNodeVisibility;
  documentType: Scalars['String'];
  hideInMenu: Scalars['Boolean'];
  scope: GQLPageTreeNodeScope;
  category: GQLPageTreeNodeCategory;
  childNodes: Array<GQLPageTreeNode>;
  parentNode: Maybe<GQLPageTreeNode>;
  path: Scalars['String'];
  parentNodes: Array<GQLPageTreeNode>;
  document: Maybe<GQLPageContentUnion>;
};

export enum GQLPageTreeNodeCategory {
  MainNavigation = 'MainNavigation'
}

export type GQLPageTreeNodeCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  pos?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  attachedDocument: GQLAttachedDocumentInput;
  hideInMenu?: Maybe<Scalars['Boolean']>;
};

export type GQLPageTreeNodeScope = {
  __typename?: 'PageTreeNodeScope';
  domain: Scalars['String'];
};

export type GQLPageTreeNodeScopeInput = {
  domain: Scalars['String'];
};

export type GQLPageTreeNodeUpdateInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  attachedDocument: GQLAttachedDocumentInput;
  hideInMenu?: Maybe<Scalars['Boolean']>;
};

export type GQLPageTreeNodeUpdateVisibilityInput = {
  visibility: GQLPageTreeNodeVisibility;
};

export enum GQLPageTreeNodeVisibility {
  Published = 'Published',
  Unpublished = 'Unpublished',
  Archived = 'Archived'
}

export type GQLPaginatedDamFiles = {
  __typename?: 'PaginatedDamFiles';
  nodes: Array<GQLDamFile>;
  totalCount: Scalars['Int'];
};

export type GQLPaginatedDamFolders = {
  __typename?: 'PaginatedDamFolders';
  nodes: Array<GQLDamFolder>;
  totalCount: Scalars['Int'];
};

export type GQLPaginatedDamItems = {
  __typename?: 'PaginatedDamItems';
  nodes: Array<GQLDamItem>;
  totalCount: Scalars['Int'];
};

export type GQLPaginatedPages = {
  __typename?: 'PaginatedPages';
  nodes: Array<GQLPage>;
  totalCount: Scalars['Int'];
};

export type GQLPaginatedRawVehicleType = {
  __typename?: 'PaginatedRawVehicleType';
  nodes: Array<GQLRawVehicle>;
  totalCount: Scalars['Int'];
  nextPage: Maybe<Scalars['Int']>;
  previousPage: Maybe<Scalars['Int']>;
  totalPages: Maybe<Scalars['Int']>;
  facets: Maybe<GQLVehicleFacetsType>;
};

export type GQLPaginatedRedirects = {
  __typename?: 'PaginatedRedirects';
  nodes: Array<GQLRedirect>;
  totalCount: Scalars['Int'];
};

export type GQLPaginatedScheme = {
  __typename?: 'PaginatedScheme';
  nodes: Array<GQLScheme>;
  totalCount: Scalars['Int'];
  nextPage: Maybe<Scalars['Int']>;
  previousPage: Maybe<Scalars['Int']>;
  totalPages: Maybe<Scalars['Int']>;
};

export type GQLPaginatedSchemeUrl = {
  __typename?: 'PaginatedSchemeUrl';
  nodes: Array<GQLSchemeUrl>;
  totalCount: Scalars['Int'];
  nextPage: Maybe<Scalars['Int']>;
  previousPage: Maybe<Scalars['Int']>;
  totalPages: Maybe<Scalars['Int']>;
};

export type GQLPaginatedSyncVehicles = {
  __typename?: 'PaginatedSyncVehicles';
  nodes: Array<GQLSyncVehicle>;
  totalCount: Scalars['Int'];
  nextPage: Maybe<Scalars['Int']>;
  previousPage: Maybe<Scalars['Int']>;
  totalPages: Maybe<Scalars['Int']>;
};

export type GQLPaginatedSyncs = {
  __typename?: 'PaginatedSyncs';
  nodes: Array<GQLSync>;
  totalCount: Scalars['Int'];
  nextPage: Maybe<Scalars['Int']>;
  previousPage: Maybe<Scalars['Int']>;
  totalPages: Maybe<Scalars['Int']>;
};

export type GQLParamControlEntity = {
  __typename?: 'ParamControlEntity';
  type: Scalars['String'];
  dataType: Maybe<Scalars['String']>;
  style: Maybe<Scalars['String']>;
};

export type GQLParamDataEntity = {
  __typename?: 'ParamDataEntity';
  default: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
  valueFromAttribute: Maybe<Scalars['String']>;
};

export type GQLParametersEntity = {
  __typename?: 'ParametersEntity';
  id: Scalars['String'];
  label: Scalars['String'];
  help: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  units: Maybe<Scalars['String']>;
  data: Maybe<Array<GQLParamDataEntity>>;
  control: Maybe<GQLParamControlEntity>;
};

export type GQLParametersEntityResponse = {
  __typename?: 'ParametersEntityResponse';
  id: Scalars['String'];
  label: Scalars['String'];
  help: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  units: Maybe<Scalars['String']>;
  data: Maybe<Array<Scalars['String']>>;
  control: Maybe<GQLParamControlEntity>;
};

export type GQLPersonalData = {
  austrianNationals: Scalars['Boolean'];
  dateOfBirth: Scalars['String'];
  employmentRelationship: Scalars['Boolean'];
  monthlyFixedCosts: Scalars['Float'];
  monthlyIncome: Scalars['Float'];
  paymentObligationsProperly: Scalars['Boolean'];
  durationOfEmploymentInYears?: Maybe<Scalars['String']>;
};

export type GQLPrice = {
  __typename?: 'Price';
  price: Maybe<GQLFormattedValue>;
  listPrice: Maybe<GQLFormattedValue>;
};

export type GQLProductData = {
  __typename?: 'ProductData';
  rate: Scalars['Float'];
  cashBenefit: Scalars['Int'];
};

export type GQLQuery = {
  __typename?: 'Query';
  gatsbyBuilds: Array<GQLBuild>;
  scheme: GQLScheme;
  schemeDetails: Array<GQLSchemeDetail>;
  schemes: GQLPaginatedScheme;
  schemeUrls: GQLPaginatedSchemeUrl;
  allSchemeUrls: Array<GQLSchemeUrl>;
  vehicles: GQLPaginatedRawVehicleType;
  allVehicles: GQLPaginatedRawVehicleType;
  vehicle: GQLRawVehicle;
  brandsAndModelGroups: GQLBrandModelGroupFacetsType;
  checkIfBoniIsAvailable: Maybe<GQLBoniCheck>;
  getBoniCheckParameter: Maybe<GQLBoniCheck>;
  syncs: GQLPaginatedSyncs;
  sync: Maybe<GQLSync>;
  syncVehicles: GQLPaginatedSyncVehicles;
  syncVehicle: Maybe<GQLSyncVehicle>;
  link: Maybe<GQLLink>;
  page: Maybe<GQLPage>;
  pages: GQLPaginatedPages;
  pageTreeNode: Maybe<GQLPageTreeNode>;
  pageTreeNodeByPath: Maybe<GQLPageTreeNode>;
  pageTreeNodeList: Array<GQLPageTreeNode>;
  pageTreeNodeSlugAvailable: GQLSlugAvailability;
  /** @deprecated Use paginatedRedirects instead. Will be removed in the next version. */
  redirects: Array<GQLRedirect>;
  paginatedRedirects: GQLPaginatedRedirects;
  redirect: GQLRedirect;
  redirectSourceAvailable: Scalars['Boolean'];
  damItemsList: GQLPaginatedDamItems;
  damFilesList: GQLPaginatedDamFiles;
  damFile: GQLDamFile;
  damIsFilenameOccupied: Scalars['Boolean'];
  damAreFilenamesOccupied: Array<GQLFilenameResponse>;
  damFoldersList: GQLPaginatedDamFolders;
  damFolder: GQLDamFolder;
  damFolderByNameAndParentId: Maybe<GQLDamFolder>;
  cascoFile: Maybe<GQLCascoFile>;
};


export type GQLQuerygatsbyBuildsArgs = {
  scope: GQLContentScopeInput;
  limit?: Maybe<Scalars['Float']>;
};


export type GQLQueryschemeArgs = {
  id: Scalars['Int'];
};


export type GQLQueryschemeDetailsArgs = {
  financeType?: Maybe<GQLFinanceType>;
  brand?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};


export type GQLQueryschemesArgs = {
  page?: Scalars['Int'];
  size?: Scalars['Int'];
  scope: GQLContentScopeInput;
};


export type GQLQueryschemeUrlsArgs = {
  page?: Scalars['Int'];
  size?: Scalars['Int'];
  schemeId?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  schemeUrlVersion: Scalars['String'];
};


export type GQLQueryallSchemeUrlsArgs = {
  schemeUrlVersion: Scalars['String'];
};


export type GQLQueryvehiclesArgs = {
  page?: Scalars['Int'];
  size?: Scalars['Int'];
  brands?: Maybe<Array<Scalars['String']>>;
  modelGroups?: Maybe<Array<Scalars['String']>>;
  colors?: Maybe<Array<Scalars['String']>>;
  mileage?: Maybe<GQLRangeInput>;
  firstRegistrationYear?: Maybe<GQLRangeInput>;
  powerPS?: Maybe<GQLRangeInput>;
  bodyTypes?: Maybe<Array<Scalars['String']>>;
  fuelTypes?: Maybe<Array<Scalars['String']>>;
  provinces?: Maybe<Array<Scalars['String']>>;
  driveTypes?: Maybe<Array<Scalars['String']>>;
  carType?: Maybe<Scalars['String']>;
  maximumRate?: Maybe<Scalars['Float']>;
  financeRuntime?: Maybe<Scalars['Int']>;
  downPayment?: Maybe<Scalars['Float']>;
  yearlyMileage?: Maybe<Scalars['Int']>;
  financeType?: Maybe<GQLFinanceType>;
  query?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  sort?: Maybe<GQLVehicleSort>;
  sortOrder?: Maybe<GQLSortOrder>;
  remainingCredit?: Maybe<Scalars['Int']>;
  scope: GQLContentScopeInput;
};


export type GQLQueryallVehiclesArgs = {
  page?: Scalars['Int'];
  size?: Scalars['Int'];
  brands?: Maybe<Array<Scalars['String']>>;
  modelGroups?: Maybe<Array<Scalars['String']>>;
  colors?: Maybe<Array<Scalars['String']>>;
  mileage?: Maybe<GQLRangeInput>;
  firstRegistrationYear?: Maybe<GQLRangeInput>;
  powerPS?: Maybe<GQLRangeInput>;
  bodyTypes?: Maybe<Array<Scalars['String']>>;
  fuelTypes?: Maybe<Array<Scalars['String']>>;
  provinces?: Maybe<Array<Scalars['String']>>;
  driveTypes?: Maybe<Array<Scalars['String']>>;
  carType?: Maybe<Scalars['String']>;
  maximumRate?: Maybe<Scalars['Float']>;
  financeRuntime?: Maybe<Scalars['Int']>;
  downPayment?: Maybe<Scalars['Float']>;
  yearlyMileage?: Maybe<Scalars['Int']>;
  financeType?: Maybe<GQLFinanceType>;
  query?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  sort?: Maybe<GQLVehicleSort>;
  sortOrder?: Maybe<GQLSortOrder>;
  remainingCredit?: Maybe<Scalars['Int']>;
  scope: GQLContentScopeInput;
};


export type GQLQueryvehicleArgs = {
  id: Scalars['String'];
  scope: GQLContentScopeInput;
};


export type GQLQuerybrandsAndModelGroupsArgs = {
  scope: GQLContentScopeInput;
  brands?: Maybe<Array<Scalars['String']>>;
};


export type GQLQuerycheckIfBoniIsAvailableArgs = {
  vehicleId: Scalars['String'];
  financeType?: Maybe<GQLFinanceType>;
  financeData: GQLFinanceDataInput;
  vehicleSearchData: GQLVehicleSearchData;
  personalData: GQLPersonalData;
  scope: GQLContentScopeInput;
};


export type GQLQuerysyncsArgs = {
  page?: Scalars['Int'];
  size?: Scalars['Int'];
  status?: Maybe<GQLSyncStatus>;
  scope: GQLContentScopeInput;
};


export type GQLQuerysyncArgs = {
  id: Scalars['ID'];
};


export type GQLQuerysyncVehiclesArgs = {
  page?: Scalars['Int'];
  size?: Scalars['Int'];
  syncId?: Maybe<Scalars['ID']>;
  status?: Maybe<GQLSyncVehicleStatus>;
  query?: Maybe<Scalars['String']>;
};


export type GQLQuerysyncVehicleArgs = {
  id: Scalars['ID'];
};


export type GQLQuerylinkArgs = {
  linkId: Scalars['ID'];
};


export type GQLQuerypageArgs = {
  pageId: Scalars['ID'];
};


export type GQLQuerypagesArgs = {
  offset?: Scalars['Int'];
  limit?: Scalars['Int'];
  sortColumnName?: Maybe<Scalars['String']>;
  sortDirection?: GQLSortDirection;
};


export type GQLQuerypageTreeNodeArgs = {
  id: Scalars['ID'];
};


export type GQLQuerypageTreeNodeByPathArgs = {
  path: Scalars['String'];
  scope: GQLPageTreeNodeScopeInput;
};


export type GQLQuerypageTreeNodeListArgs = {
  scope: GQLPageTreeNodeScopeInput;
  category?: Maybe<Scalars['String']>;
};


export type GQLQuerypageTreeNodeSlugAvailableArgs = {
  scope: GQLPageTreeNodeScopeInput;
  parentId?: Maybe<Scalars['ID']>;
  slug: Scalars['String'];
};


export type GQLQueryredirectsArgs = {
  scope?: GQLRedirectScopeInput;
  query?: Maybe<Scalars['String']>;
  type?: Maybe<GQLRedirectGenerationType>;
  active?: Maybe<Scalars['Boolean']>;
  sortColumnName?: Maybe<Scalars['String']>;
  sortDirection?: GQLSortDirection;
};


export type GQLQuerypaginatedRedirectsArgs = {
  scope?: GQLRedirectScopeInput;
  search?: Maybe<Scalars['String']>;
  filter?: Maybe<GQLRedirectFilter>;
  sort?: Maybe<Array<GQLRedirectSort>>;
  offset?: Scalars['Int'];
  limit?: Scalars['Int'];
};


export type GQLQueryredirectArgs = {
  id: Scalars['ID'];
};


export type GQLQueryredirectSourceAvailableArgs = {
  scope?: GQLRedirectScopeInput;
  source: Scalars['String'];
};


export type GQLQuerydamItemsListArgs = {
  offset?: Scalars['Int'];
  limit?: Scalars['Int'];
  sortColumnName?: Maybe<Scalars['String']>;
  sortDirection?: GQLSortDirection;
  folderId?: Maybe<Scalars['ID']>;
  includeArchived?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<GQLDamItemFilterInput>;
};


export type GQLQuerydamFilesListArgs = {
  offset?: Scalars['Int'];
  limit?: Scalars['Int'];
  sortColumnName?: Maybe<Scalars['String']>;
  sortDirection?: GQLSortDirection;
  folderId?: Maybe<Scalars['ID']>;
  includeArchived?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<GQLFileFilterInput>;
};


export type GQLQuerydamFileArgs = {
  id: Scalars['ID'];
};


export type GQLQuerydamIsFilenameOccupiedArgs = {
  filename: Scalars['String'];
  folderId?: Maybe<Scalars['String']>;
};


export type GQLQuerydamAreFilenamesOccupiedArgs = {
  filenames: Array<GQLFilenameInput>;
};


export type GQLQuerydamFoldersListArgs = {
  offset?: Scalars['Int'];
  limit?: Scalars['Int'];
  sortColumnName?: Maybe<Scalars['String']>;
  sortDirection?: GQLSortDirection;
  parentId?: Maybe<Scalars['ID']>;
  includeArchived?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<GQLFolderFilterInput>;
};


export type GQLQuerydamFolderArgs = {
  id: Scalars['ID'];
};


export type GQLQuerydamFolderByNameAndParentIdArgs = {
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};


export type GQLQuerycascoFileArgs = {
  scope: GQLContentScopeInput;
};

export type GQLRangeInput = {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
};

export type GQLRangeInputType = {
  __typename?: 'RangeInputType';
  min: Maybe<Scalars['Int']>;
  max: Maybe<Scalars['Int']>;
};

export type GQLRangeType = {
  __typename?: 'RangeType';
  min: Maybe<Scalars['Float']>;
  max: Maybe<Scalars['Float']>;
};

export type GQLRate = {
  __typename?: 'Rate';
  rate: Scalars['Float'];
  downPayment: Scalars['Float'];
};

export type GQLRawVehicle = {
  __typename?: 'RawVehicle';
  vehicleId: Scalars['String'];
  externalID: Maybe<Scalars['String']>;
  vehicleLink: Maybe<Scalars['String']>;
  offerReference: Maybe<Scalars['String']>;
  brand: Maybe<Scalars['String']>;
  modelGroup: Maybe<Scalars['String']>;
  model: Maybe<Scalars['String']>;
  modelCode: Maybe<Scalars['String']>;
  modelVariant: Maybe<Scalars['String']>;
  eurotaxCode: Maybe<Scalars['String']>;
  bodyColor: Maybe<GQLBodyColor>;
  availability: Maybe<GQLAvailability>;
  firstRegistration: Maybe<Scalars['String']>;
  powerPS: Maybe<Scalars['Float']>;
  powerKW: Maybe<Scalars['Float']>;
  mileage: Maybe<Scalars['Float']>;
  cylinderCapacity: Maybe<GQLFormattedValue>;
  driveType: Maybe<GQLFormattedValue>;
  transmission: Maybe<GQLFormattedValue>;
  prices: Maybe<GQLPrice>;
  doors: Maybe<Scalars['Float']>;
  seats: Maybe<Scalars['Float']>;
  leasingCapable: Maybe<Scalars['Boolean']>;
  vatDeductible: Maybe<Scalars['Boolean']>;
  bodyType: Maybe<GQLFormattedValue>;
  previousOwners: Maybe<Scalars['Float']>;
  selfWeight: Maybe<Scalars['Float']>;
  totalWeight: Maybe<Scalars['Float']>;
  garantie: Maybe<Scalars['String']>;
  extraInfo: Maybe<Scalars['String']>;
  vin: Maybe<Scalars['String']>;
  images: Maybe<Array<GQLImage>>;
  location: Maybe<GQLLocation>;
  dealer: Maybe<GQLDealer>;
  category: Maybe<Scalars['String']>;
  serviceBook: Maybe<Scalars['Boolean']>;
  netListPrice: Maybe<Scalars['Float']>;
  importDatum: Maybe<Scalars['String']>;
  equipment: Maybe<Array<GQLEquipment>>;
  equipmentNew: Maybe<Array<GQLEquipment>>;
  kampagnen: Maybe<Array<GQLCampaign>>;
  isAuction: Maybe<Scalars['String']>;
  nwHighlightsEquipment: Maybe<Array<GQLEquipment>>;
  nwSeriesEquipment: Maybe<Array<GQLEquipment>>;
  bstId: Maybe<GQLBst>;
  timestamp: Maybe<Scalars['Float']>;
  lkw: Maybe<Scalars['Boolean']>;
  fuel: Maybe<GQLFuel>;
  type: Maybe<GQLVehicleType>;
  brandSlug: Maybe<Scalars['String']>;
  modelGroupSlug: Maybe<Scalars['String']>;
  bodyColorSlug: Maybe<Scalars['String']>;
  bodyTypeSlug: Maybe<Scalars['String']>;
  fuelSlug: Maybe<Scalars['String']>;
  provinceSlug: Maybe<Scalars['String']>;
  sumOfBankPromotions: Scalars['Float'];
  rate: Maybe<Scalars['Int']>;
  downPayment: Maybe<Scalars['Float']>;
  offers: Maybe<Array<GQLOffer>>;
  finCalcData: Maybe<GQLFinanceData>;
  finCalcProducts: Maybe<GQLProductData>;
  finCalcRequestXML: Maybe<Scalars['String']>;
  finCalcDefaultRequestXML: Maybe<Scalars['String']>;
  financeInformation: Maybe<GQLFinanceInformation>;
};


export type GQLRawVehiclerateArgs = {
  financeType?: Maybe<GQLFinanceType>;
  financeRuntime?: Maybe<Scalars['Int']>;
  downPayment?: Maybe<Scalars['Float']>;
  yearlyMileage?: Maybe<Scalars['Int']>;
  residualCreditInPercent?: Scalars['Int'];
};


export type GQLRawVehicledownPaymentArgs = {
  financeType?: Maybe<GQLFinanceType>;
  financeRuntime?: Maybe<Scalars['Int']>;
  downPayment?: Maybe<Scalars['Float']>;
  yearlyMileage?: Maybe<Scalars['Int']>;
  residualCreditInPercent?: Scalars['Int'];
};


export type GQLRawVehicleoffersArgs = {
  financeType?: Maybe<GQLFinanceType>;
};


export type GQLRawVehiclefinCalcDataArgs = {
  financeType?: Maybe<GQLFinanceType>;
  financeRuntime?: Maybe<Scalars['Int']>;
  downPayment?: Maybe<Scalars['Float']>;
  yearlyMileage?: Maybe<Scalars['Int']>;
  residualCreditInPercent?: Scalars['Int'];
};


export type GQLRawVehiclefinCalcProductsArgs = {
  financeType?: Maybe<GQLFinanceType>;
};


export type GQLRawVehiclefinanceInformationArgs = {
  financeType?: Maybe<GQLFinanceType>;
  args: GQLFinanceCalculationInput;
};

export type GQLRedirect = GQLDocumentInterface & {
  __typename?: 'Redirect';
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  sourceType: GQLRedirectSourceTypeValues;
  source: Scalars['String'];
  target: Scalars['JSONObject'];
  comment: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  generationType: GQLRedirectGenerationType;
  createdAt: Scalars['DateTime'];
};

export type GQLRedirectFilter = {
  generationType?: Maybe<GQLStringFilter>;
  source?: Maybe<GQLStringFilter>;
  active?: Maybe<GQLBooleanFilter>;
  createdAt?: Maybe<GQLDateFilter>;
  updatedAt?: Maybe<GQLDateFilter>;
  and?: Maybe<Array<GQLRedirectFilter>>;
  or?: Maybe<Array<GQLRedirectFilter>>;
};

export enum GQLRedirectGenerationType {
  manual = 'manual',
  automatic = 'automatic'
}

export type GQLRedirectInput = {
  sourceType: GQLRedirectSourceTypeValues;
  source: Scalars['String'];
  target: Scalars['JSONObject'];
  comment?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  generationType: GQLRedirectGenerationType;
};

export type GQLRedirectScopeInput = {
  thisScopeHasNoFields____?: Maybe<Scalars['String']>;
};

export type GQLRedirectSort = {
  field: GQLRedirectSortField;
  direction?: GQLSortDirection;
};

export enum GQLRedirectSortField {
  source = 'source',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt'
}

export enum GQLRedirectSourceTypeValues {
  path = 'path'
}

export type GQLRedirectUpdateActivenessInput = {
  active: Scalars['Boolean'];
};

export type GQLScheme = {
  __typename?: 'Scheme';
  id: Scalars['Float'];
  url: Scalars['String'];
  headline: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  schemeUrls: Array<GQLSchemeUrl>;
  scope: GQLContentScope;
};

export type GQLSchemeDetail = {
  __typename?: 'SchemeDetail';
  headline: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};

export type GQLSchemeUrl = {
  __typename?: 'SchemeUrl';
  id: Scalars['ID'];
  url: Scalars['String'];
  active: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  scheme: GQLScheme;
  schemeUrlVersion: Scalars['String'];
  filters: GQLFacetArguments;
};


export type GQLSchemeUrlfiltersArgs = {
  scope: GQLContentScopeInput;
};

export enum GQLSlugAvailability {
  Available = 'Available',
  Taken = 'Taken',
  Reserved = 'Reserved'
}

export enum GQLSortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum GQLSortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type GQLStringFilter = {
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equal?: Maybe<Scalars['String']>;
  notEqual?: Maybe<Scalars['String']>;
};

export type GQLSync = {
  __typename?: 'Sync';
  id: Scalars['ID'];
  start: Maybe<Scalars['DateTime']>;
  end: Maybe<Scalars['DateTime']>;
  status: GQLSyncStatus;
  message: Maybe<Scalars['String']>;
  scope: GQLContentScope;
  statistics: GQLSyncStatistics;
};

export type GQLSyncStatistics = {
  __typename?: 'SyncStatistics';
  successfulVehicles: Scalars['Int'];
  skippedVehicles: Scalars['Int'];
  failedVehicles: Scalars['Int'];
};

export enum GQLSyncStatus {
  Running = 'Running',
  Success = 'Success',
  Failed = 'Failed'
}

export type GQLSyncVehicle = {
  __typename?: 'SyncVehicle';
  id: Scalars['ID'];
  vehicleId: Scalars['ID'];
  sync: GQLSync;
  status: GQLSyncVehicleStatus;
  reason: Maybe<Scalars['String']>;
  modelGroup: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  firstRegistration: Maybe<Scalars['String']>;
  mileage: Maybe<Scalars['Int']>;
  listPrice: Maybe<Scalars['String']>;
  price: Maybe<Scalars['String']>;
  finCalcRequestXml: Maybe<Scalars['String']>;
};

export enum GQLSyncVehicleStatus {
  Success = 'Success',
  Failed = 'Failed',
  Skipped = 'Skipped'
}

export type GQLUpdateCascoFileInput = {
  fileId: Scalars['ID'];
};

export type GQLUpdateDamFileInput = {
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  altText?: Maybe<Scalars['String']>;
  image?: Maybe<GQLUpdateImageFileInput>;
  folderId?: Maybe<Scalars['ID']>;
};

export type GQLUpdateDamFolderInput = {
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type GQLUpdateImageFileInput = {
  cropArea?: Maybe<GQLImageCropAreaInput>;
};

export type GQLUpdateSchemeInput = {
  headline?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type GQLUpdateSchemeUrlInput = {
  url?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  schemeUrlVersion?: Maybe<Scalars['String']>;
};

export type GQLVehicleFacetsType = {
  __typename?: 'VehicleFacetsType';
  mileage: Maybe<GQLRangeType>;
  brands: Maybe<Array<GQLDocumentFacet>>;
  firstRegistrationYear: Maybe<GQLRangeType>;
  modelGroups: Maybe<Array<GQLModelGroupFacet>>;
  fuelTypes: Maybe<Array<GQLDocumentFacet>>;
  colors: Maybe<Array<GQLDocumentFacet>>;
  provinces: Maybe<Array<GQLDocumentFacet>>;
  powerPS: Maybe<GQLRangeType>;
  driveTypes: Maybe<Array<GQLDocumentFacet>>;
  bodyTypes: Maybe<Array<GQLDocumentFacet>>;
  downPayment: Maybe<GQLRangeType>;
  carType: Maybe<GQLCarType>;
};

export type GQLVehicleSearchData = {
  downPayment: Scalars['Int'];
  duration: Scalars['Int'];
  annualMileage: Scalars['Int'];
};

export enum GQLVehicleSort {
  rate = 'rate',
  fuelConsumption = 'fuelConsumption',
  mileage = 'mileage',
  price = 'price',
  firstRegistration = 'firstRegistration',
  powerPS = 'powerPS'
}

export enum GQLVehicleType {
  carHub = 'carHub',
  weltAuto = 'weltAuto'
}

export type GQLBoniCheckParameterFragment = { __typename?: 'BoniCheck', title: string, disclaimer: string, info: string, groups: Maybe<Array<{ __typename?: 'GroupsEntity', name: string, parameters: Maybe<Array<{ __typename?: 'ParametersEntity', id: string, label: string, help: Maybe<string>, value: Maybe<string>, type: Maybe<string>, units: Maybe<string>, control: Maybe<{ __typename?: 'ParamControlEntity', type: string, dataType: Maybe<string>, style: Maybe<string> }>, data: Maybe<Array<{ __typename?: 'ParamDataEntity', default: Maybe<string>, value: Maybe<string>, valueFromAttribute: Maybe<string> }>> }>> }>>, control: { __typename?: 'ControlEntity', checkPossible: boolean }, errors: Maybe<Array<{ __typename?: 'ErrorEntity', field: string, description: string }>> };

export type GQLBoniCheckQueryVariables = Exact<{
  id: Scalars['String'];
  leasemeScope: GQLContentScopeInput;
}>;


export type GQLBoniCheckQuery = { __typename?: 'Query', FinCalcProducts: { __typename?: 'RawVehicle', finCalcProductsLeasing: Maybe<{ __typename?: 'ProductData', rate: number, cashBenefit: number }>, finCalcProductsCredit: Maybe<{ __typename?: 'ProductData', rate: number, cashBenefit: number }> }, BoniCheckParameter: Maybe<(
    { __typename?: 'BoniCheck' }
    & GQLBoniCheckParameterFragment
  )> };

export type GQLBoniCheckUpdateQueryVariables = Exact<{
  vehicleId: Scalars['String'];
  financeData: GQLFinanceDataInput;
  vehicleSearchData: GQLVehicleSearchData;
  personalData: GQLPersonalData;
  leasemeScope: GQLContentScopeInput;
}>;


export type GQLBoniCheckUpdateQuery = { __typename?: 'Query', checkIfBoniIsAvailable: Maybe<(
    { __typename?: 'BoniCheck' }
    & GQLBoniCheckParameterFragment
  )> };

export type GQLAllSchemeUrlsQueryVariables = Exact<{
  schemeUrlVersion: Scalars['String'];
}>;


export type GQLAllSchemeUrlsQuery = { __typename?: 'Query', allSchemeUrls: Array<{ __typename?: 'SchemeUrl', url: string }> };

export type GQLFinanceInformationErrorFragmentFragment = { __typename?: 'FinanceInformationError', type: string, group: Maybe<string>, field: Maybe<string>, description: string, id: string };

export type GQLFinanceInformationParameterFragmentFragment = { __typename?: 'FinanceInformationParameter', id: Maybe<string>, label: Maybe<string>, type: Maybe<string>, selectType: Maybe<string>, dataType: Maybe<string>, unit: Maybe<string>, value: Maybe<string>, defaultOption: Maybe<string>, help: Maybe<string>, bold: Maybe<boolean>, length: Maybe<string>, options: Maybe<Array<{ __typename?: 'FinanceInformationParameterOption', label: Maybe<string>, value: Maybe<string> }>>, error: Maybe<(
    { __typename?: 'FinanceInformationError' }
    & GQLFinanceInformationErrorFragmentFragment
  )> };

export type GQLFinanceInformationGroupFragmentFragment = { __typename?: 'FinanceInformationGroup', label: Maybe<string>, disclaimer: Maybe<string>, parameters: Array<(
    { __typename?: 'FinanceInformationParameter' }
    & GQLFinanceInformationParameterFragmentFragment
  )> };

export type GQLFinanceInformationQueryVariables = Exact<{
  id: Scalars['String'];
  leasemeScope: GQLContentScopeInput;
  args: GQLFinanceCalculationInput;
  financeType?: Maybe<GQLFinanceType>;
}>;


export type GQLFinanceInformationQuery = { __typename?: 'Query', vehicle: { __typename?: 'RawVehicle', vehicleId: string, financeInformation: Maybe<{ __typename?: 'FinanceInformation', productDisclaimer: Maybe<string>, globalDisclaimer: Maybe<string>, groups: Array<(
        { __typename?: 'FinanceInformationGroup' }
        & GQLFinanceInformationGroupFragmentFragment
      )>, errors: Maybe<Array<(
        { __typename?: 'FinanceInformationError' }
        & GQLFinanceInformationErrorFragmentFragment
      )>> }> } };

export type GQLVehicleCardVehicleFragment = { __typename?: 'RawVehicle', model: Maybe<string>, vehicleId: string, rate: Maybe<number>, downPayment: Maybe<number>, images: Maybe<Array<{ __typename?: 'Image', uri: Maybe<string> }>> };

export type GQLVehicleDetailDealerInfoFragment = { __typename?: 'Dealer', stageimgUrl: Maybe<string>, name: Maybe<string>, street: Maybe<string>, city: Maybe<string>, zip: Maybe<string>, telephone: Maybe<string>, longitude: Maybe<number>, latitude: Maybe<number>, bnr: number, openingTimes: Maybe<Array<(
    { __typename?: 'OpeningTimes' }
    & GQLDealerInfoOpeningTimeFragment
  )>> };

export type GQLDealerInfoOpeningTimeFragment = { __typename?: 'OpeningTimes', title: Maybe<string>, mon: Maybe<Array<string>>, tue: Maybe<Array<string>>, wed: Maybe<Array<string>>, thu: Maybe<Array<string>>, fri: Maybe<Array<string>>, sat: Maybe<Array<string>>, sun: Maybe<Array<string>> };

export type GQLVehicleDetailCurrentPriceInfoQueryVariables = Exact<{
  id: Scalars['String'];
  financeType?: Maybe<GQLFinanceType>;
  financeRuntime?: Maybe<Scalars['Int']>;
  downPaymentLeasing?: Maybe<Scalars['Float']>;
  downPaymentCredit?: Maybe<Scalars['Float']>;
  yearlyMileage?: Maybe<Scalars['Int']>;
  residualCreditInPercent?: Maybe<Scalars['Int']>;
  leasemeScope: GQLContentScopeInput;
}>;


export type GQLVehicleDetailCurrentPriceInfoQuery = { __typename?: 'Query', currentPriceInfo: { __typename?: 'RawVehicle', finCalcData: Maybe<{ __typename?: 'FinanceData', downPayment: Maybe<number>, disclaimer: Maybe<string>, residualValue: Maybe<number>, rate: Maybe<number>, processingCostAmount: Maybe<number>, interestRate: Maybe<number>, effectivRate: Maybe<number>, sumAllPayments: Maybe<number>, financingSum: Maybe<number> }> } };

export type GQLLandingPageFragment = { __typename?: 'SchemeUrl', url: string, disabled: boolean, filters: { __typename?: 'FacetArguments', financeType: Maybe<GQLFinanceType>, maximumRate: Maybe<number>, downPayment: Maybe<number>, brands: Maybe<Array<string>>, modelGroups: Maybe<Array<string>>, bodyTypes: Maybe<Array<string>>, provinces: Maybe<Array<string>> }, scheme: { __typename?: 'Scheme', headline: Maybe<string>, title: Maybe<string>, description: Maybe<string>, content: Maybe<string> } };

export type GQLLandingPagesQueryVariables = Exact<{
  page: Scalars['Int'];
  schemeUrlVersion: Scalars['String'];
  scope: GQLContentScopeInput;
}>;


export type GQLLandingPagesQuery = { __typename?: 'Query', schemeUrls: { __typename?: 'PaginatedSchemeUrl', nextPage: Maybe<number>, nodes: Array<(
      { __typename?: 'SchemeUrl' }
      & GQLLandingPageFragment
    )> } };

export type GQLVehicleSearchResultsQueryVariables = Exact<{
  page: Scalars['Int'];
  size: Scalars['Int'];
  maximumRate?: Maybe<Scalars['Float']>;
  financeType?: Maybe<GQLFinanceType>;
  financeRuntime?: Maybe<Scalars['Int']>;
  downPayment?: Maybe<Scalars['Float']>;
  yearlyMileage?: Maybe<Scalars['Int']>;
  brands?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  modelGroups?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  colors?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  minMileage?: Maybe<Scalars['Int']>;
  maxMileage?: Maybe<Scalars['Int']>;
  minFirstRegistration?: Maybe<Scalars['Int']>;
  maxFirstRegistration?: Maybe<Scalars['Int']>;
  minPS?: Maybe<Scalars['Int']>;
  maxPS?: Maybe<Scalars['Int']>;
  bodyTypes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  fuelTypes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  provinces?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  sort?: Maybe<GQLVehicleSort>;
  sortOrder?: Maybe<GQLSortOrder>;
  leasemeScope: GQLContentScopeInput;
  carType?: Maybe<Scalars['String']>;
  remainingCredit?: Maybe<Scalars['Int']>;
}>;


export type GQLVehicleSearchResultsQuery = { __typename?: 'Query', vehicles: { __typename?: 'PaginatedRawVehicleType', totalCount: number, totalPages: Maybe<number>, nodes: Array<(
      { __typename?: 'RawVehicle' }
      & GQLVehicleSearchResultVehicleFragment
    )>, facets: Maybe<(
      { __typename?: 'VehicleFacetsType' }
      & GQLFacetsFragment
    )> } };

export type GQLVehicleSearchResultVehicleFragment = { __typename?: 'RawVehicle', vehicleId: string, type: Maybe<GQLVehicleType>, brand: Maybe<string>, modelGroup: Maybe<string>, model: Maybe<string>, mileage: Maybe<number>, firstRegistration: Maybe<string>, powerPS: Maybe<number>, vehicleLink: Maybe<string>, rate: Maybe<number>, downPayment: Maybe<number>, location: Maybe<{ __typename?: 'Location', province: Maybe<string> }>, fuel: Maybe<{ __typename?: 'Fuel', text: Maybe<string> }>, images: Maybe<Array<{ __typename?: 'Image', uri: Maybe<string> }>>, dealer: Maybe<{ __typename?: 'Dealer', city: Maybe<string>, zip: Maybe<string>, name: Maybe<string> }> };

export type GQLFacetsFragment = { __typename?: 'VehicleFacetsType', mileage: Maybe<{ __typename?: 'RangeType', min: Maybe<number>, max: Maybe<number> }>, brands: Maybe<Array<{ __typename?: 'DocumentFacet', name: string, slug: string, count: number }>>, firstRegistrationYear: Maybe<{ __typename?: 'RangeType', min: Maybe<number>, max: Maybe<number> }>, modelGroups: Maybe<Array<{ __typename?: 'ModelGroupFacet', name: string, slug: string, count: number, image: Maybe<string>, brand: Maybe<string>, brandSlug: Maybe<string>, powerPSRange: Maybe<{ __typename?: 'RangeType', min: Maybe<number>, max: Maybe<number> }>, fuelTypes: Maybe<Array<{ __typename?: 'DocumentFacet', name: string }>>, provinces: Maybe<Array<{ __typename?: 'DocumentFacet', name: string }>>, firstRegistrationRange: Maybe<{ __typename?: 'RangeType', min: Maybe<number>, max: Maybe<number> }>, mileageRange: Maybe<{ __typename?: 'RangeType', min: Maybe<number>, max: Maybe<number> }>, rateRange: Maybe<{ __typename?: 'RangeType', min: Maybe<number>, max: Maybe<number> }> }>>, fuelTypes: Maybe<Array<{ __typename?: 'DocumentFacet', name: string, slug: string, count: number }>>, provinces: Maybe<Array<{ __typename?: 'DocumentFacet', name: string, slug: string, count: number }>>, powerPS: Maybe<{ __typename?: 'RangeType', max: Maybe<number>, min: Maybe<number> }>, driveTypes: Maybe<Array<{ __typename?: 'DocumentFacet', name: string, slug: string, count: number }>>, colors: Maybe<Array<{ __typename?: 'DocumentFacet', name: string, slug: string, count: number }>>, bodyTypes: Maybe<Array<{ __typename?: 'DocumentFacet', name: string, slug: string, count: number }>>, downPayment: Maybe<{ __typename?: 'RangeType', min: Maybe<number>, max: Maybe<number> }> };

export type GQLPriceInfoFragment = { __typename?: 'RawVehicle', finCalcData: Maybe<{ __typename?: 'FinanceData', downPayment: Maybe<number>, disclaimer: Maybe<string>, residualValue: Maybe<number>, rate: Maybe<number>, processingCostAmount: Maybe<number>, interestRate: Maybe<number>, effectivRate: Maybe<number>, sumAllPayments: Maybe<number>, financingSum: Maybe<number> }> };

export type GQLVehicleDetailQueryVariables = Exact<{
  vehicleId: Scalars['String'];
  financeType?: Maybe<GQLFinanceType>;
  financeRuntime: Scalars['Int'];
  downPayment: Scalars['Float'];
  yearlyMileage: Scalars['Int'];
  leasemeScope: GQLContentScopeInput;
  residualCreditInPercent?: Maybe<Scalars['Int']>;
}>;


export type GQLVehicleDetailQuery = { __typename?: 'Query', vehicle: (
    { __typename?: 'RawVehicle', vehicleId: string, type: Maybe<GQLVehicleType>, externalID: Maybe<string>, vehicleLink: Maybe<string>, offerReference: Maybe<string>, brand: Maybe<string>, modelGroup: Maybe<string>, model: Maybe<string>, modelCode: Maybe<string>, modelVariant: Maybe<string>, eurotaxCode: Maybe<string>, firstRegistration: Maybe<string>, powerPS: Maybe<number>, powerKW: Maybe<number>, mileage: Maybe<number>, doors: Maybe<number>, seats: Maybe<number>, vatDeductible: Maybe<boolean>, previousOwners: Maybe<number>, selfWeight: Maybe<number>, totalWeight: Maybe<number>, garantie: Maybe<string>, extraInfo: Maybe<string>, vin: Maybe<string>, category: Maybe<string>, serviceBook: Maybe<boolean>, netListPrice: Maybe<number>, importDatum: Maybe<string>, isAuction: Maybe<string>, timestamp: Maybe<number>, lkw: Maybe<boolean>, leasingRate: Maybe<number>, leasingDownPayment: Maybe<number>, creditRate: Maybe<number>, creditDownPayment: Maybe<number>, equipment: Maybe<Array<{ __typename?: 'Equipment', text: Maybe<string> }>>, nwSeriesEquipment: Maybe<Array<{ __typename?: 'Equipment', text: Maybe<string> }>>, bodyColor: Maybe<{ __typename?: 'BodyColor', text: Maybe<string> }>, driveType: Maybe<{ __typename?: 'FormattedValue', value: Maybe<string>, fmt: Maybe<string> }>, images: Maybe<Array<{ __typename?: 'Image', uri: Maybe<string> }>>, fuel: Maybe<{ __typename?: 'Fuel', text: Maybe<string>, co2: Maybe<{ __typename?: 'FormattedValue', fmt: Maybe<string> }>, consumption: Maybe<{ __typename?: 'FormattedValue', fmt: Maybe<string> }> }>, bodyType: Maybe<{ __typename?: 'FormattedValue', fmt: Maybe<string> }>, cylinderCapacity: Maybe<{ __typename?: 'FormattedValue', fmt: Maybe<string> }>, transmission: Maybe<{ __typename?: 'FormattedValue', fmt: Maybe<string> }>, dealer: Maybe<(
      { __typename?: 'Dealer' }
      & GQLVehicleDetailDealerInfoFragment
    )> }
    & GQLPriceInfoFragment
  ) };
