import * as React from "react";

import { ISnackbar, SnackbarContext, SnackbarType } from "./SnackbarContext";

export default function SnackbarProvider({ children }: any) {
    const [current, setCurrent] = React.useState<ISnackbar | null>(null);

    const showSnackbar = (type: SnackbarType, message: string) => setCurrent({ type, message });

    const contextValue = {
        current,
        showSnackbar: React.useCallback((type, message) => showSnackbar(type, message), []),
    };

    React.useEffect(() => {
        if (current) {
            setTimeout(() => {
                setCurrent(null);
            }, 3000);
        }
    }, [current]);

    return <SnackbarContext.Provider value={contextValue}>{children}</SnackbarContext.Provider>;
}
