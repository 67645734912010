import { GQLFinanceType } from "@src/graphql.apollo.generated";
import { GQLAllFacetsFragment, GQLLeaseMe_DocumentFacet, Maybe } from "@src/graphql.gatsby.generated";
import getFormattedNumber from "@src/shared/utils/getFormattedNumber";
import * as React from "react";
import { useIntl } from "react-intl";

import { IFilter } from "../searchfilter/IFilter";
import { DisplayNameContext } from "./DisplayNameContext";

interface Props {
    facets?: Maybe<GQLAllFacetsFragment>;
}

const DisplayNameProvider: React.FunctionComponent<Props> = ({ children, facets }) => {
    const intl = useIntl();

    const getDisplayName = (key: keyof IFilter, value: string | null | undefined) => {
        if (!value) {
            return undefined;
        }

        switch (key) {
            case "financeType":
                if (value === GQLFinanceType.leasing) {
                    return intl.formatMessage({
                        id: "leaseme.leasing",
                        defaultMessage: "Leasing",
                    });
                } else if (value === GQLFinanceType.credit) {
                    return intl.formatMessage({
                        id: "leaseme.credit",
                        defaultMessage: "Kredit",
                    });
                } else if (value === GQLFinanceType.openEndLeasing) {
                    return intl.formatMessage({
                        id: "leaseme.openEndLeasing",
                        defaultMessage: "Open-End-Leasing",
                    });
                } else if (value === GQLFinanceType.closedEndLeasing) {
                    return intl.formatMessage({
                        id: "leaseme.closedEndLeasing",
                        defaultMessage: "Closed-End-Leasing",
                    });
                } else {
                    return undefined;
                }

            case "minKm":
            case "maxKm":
                return `${getFormattedNumber(Number(value))} km`;

            case "minPs":
            case "maxPs":
                return `${getFormattedNumber(Number(value))} PS`;

            case "minFirstRegistration":
            case "maxFirstRegistration":
                return String(value);

            case "brands":
                return findInFacets(facets?.brands, value);

            case "modelGroups":
            case "bodyTypes":
            case "fuelTypes":
            case "provinces":
                return findInFacets(facets?.[key], value);

            case "colors": {
                const name = findInFacets(facets?.[key], value);

                // rot -> Rot
                return name ? name[0].toUpperCase() + name.slice(1) : undefined;
            }

            case "carType": {
                if (value === "newCar") {
                    return intl.formatMessage({ id: "leasme.filter.carType.newCar", defaultMessage: "Neuwagen" });
                } else if (value === "usedCar") {
                    return intl.formatMessage({ id: "leasme.filter.carType.usedCar", defaultMessage: "Gebrauchtwagen" });
                }

                return undefined;
            }

            default:
                return undefined;
        }
    };

    return <DisplayNameContext.Provider value={{ getDisplayName }}>{children}</DisplayNameContext.Provider>;
};

export default DisplayNameProvider;

const findInFacets = (facets: Pick<GQLLeaseMe_DocumentFacet, "name" | "slug">[] | null | undefined, value: string) =>
    facets?.find((facet) => facet.slug === value)?.name;
