import "fontsource-asap/400-normal.css";
import "fontsource-asap/500-normal.css";
import "fontsource-asap/600-normal.css";
import "fontsource-asap/700-normal.css";
import "fontsource-roboto/100-normal.css";
import "fontsource-roboto/300-normal.css";
import "fontsource-roboto/500-normal.css";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";

import { shouldUpdateScroll } from "./src/shouldUpdateScroll";
import { wrapPageElement } from "./src/wrapPageElement";
import { wrapRootElement } from "./src/wrapRootElement";

if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/access-token-service-worker.js");
}

export { wrapRootElement, shouldUpdateScroll, wrapPageElement };
