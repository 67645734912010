export const numeralLocaleDe: NumeralJSLocale = {
    delimiters: {
        thousands: ".",
        decimal: ",",
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t",
    },
    ordinal() {
        return ".";
    },
    currency: {
        symbol: "€",
    },
};

export const numeralLocaleHu: NumeralJSLocale = {
    delimiters: {
        thousands: " ",
        decimal: ",",
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t",
    },
    ordinal() {
        return ".";
    },
    currency: {
        symbol: "Ft",
    },
};
