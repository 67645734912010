// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-page-tsx": () => import("./../../../src/pages/admin/page.tsx" /* webpackChunkName: "component---src-pages-admin-page-tsx" */),
  "component---src-pages-detail-tsx": () => import("./../../../src/pages/detail.tsx" /* webpackChunkName: "component---src-pages-detail-tsx" */),
  "component---src-skeleton-home-home-page-template-tsx": () => import("./../../../src/skeleton/home/HomePage.template.tsx" /* webpackChunkName: "component---src-skeleton-home-home-page-template-tsx" */),
  "component---src-skeleton-search-results-modelsearchresults-model-search-result-template-tsx": () => import("./../../../src/skeleton/searchResults/modelsearchresults/ModelSearchResult.template.tsx" /* webpackChunkName: "component---src-skeleton-search-results-modelsearchresults-model-search-result-template-tsx" */),
  "component---src-skeleton-search-results-vehicle-search-results-vehicle-search-result-template-tsx": () => import("./../../../src/skeleton/searchResults/vehicleSearchResults/VehicleSearchResult.template.tsx" /* webpackChunkName: "component---src-skeleton-search-results-vehicle-search-results-vehicle-search-result-template-tsx" */)
}

