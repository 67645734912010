import { GQLSortOrder, GQLVehicleSort } from "@src/graphql.apollo.generated";

export interface ISort {
    sort: GQLVehicleSort;
    sortOrder: GQLSortOrder;
}

export const DefaultSort: ISort = {
    sort: GQLVehicleSort.rate,
    sortOrder: GQLSortOrder.DESC,
};
