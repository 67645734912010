import { useQuery } from "@apollo/client";
import { WindowLocation } from "@reach/router";
import { getConfig } from "@src/config";
import { GQLAllSchemeUrlsQuery, GQLAllSchemeUrlsQueryVariables, GQLFinanceType } from "@src/graphql.apollo.generated";
import { GQLAllFacetsFragment, Maybe } from "@src/graphql.gatsby.generated";
import { AllSchemeUrlsQuery } from "@src/shared/context/searchfilter/AllSchemeUrls.gql";
import pushToDataLayer from "@src/shared/tracking/pushToDataLayer";
import { checkIfLandingPageExists } from "@src/shared/utils/checkIfLandingPageExists";
import getOptionalFilters from "@src/shared/utils/getOptionalFilters";
import isHomePage from "@src/shared/utils/isHomePage";
import isModelPage from "@src/shared/utils/isModelPage";
import { navigate } from "gatsby";
import * as React from "react";

import { DefaultSort, ISort } from "../sort/ISort";
import { generateUrlFromFilter as generateUrl } from "./generateUrlFromFilter";
import { getDefaultFilter, IFilter, IOptionalFilter } from "./IFilter";
import { parseFilterFromUrl } from "./parseFilterFromUrl";
import { SearchFilterContext } from "./SearchFilterContext";

export interface ISearchFilterProviderProps {
    location: WindowLocation;
    facets?: Maybe<GQLAllFacetsFragment>;
}

const SearchFilterProvider: React.FunctionComponent<ISearchFilterProviderProps> = ({ children, location, facets }) => {
    const scope = getConfig("scope");
    const schemeUrlVersion = `live-${scope.domain}`;
    const { data: allSchemeUrls } = useQuery<GQLAllSchemeUrlsQuery, GQLAllSchemeUrlsQueryVariables>(AllSchemeUrlsQuery, {
        variables: { schemeUrlVersion },
    });

    const [internalFilter, setInternalFilter] = React.useState(parseFilterFromUrl(location, facets));

    React.useEffect(() => {
        setInternalFilter(parseFilterFromUrl(location, facets));
    }, [location, facets]);

    const usedOptionalFilter = React.useMemo(() => {
        return getOptionalFilters(internalFilter);
    }, [internalFilter]);

    const [mobileFilterOpen, setMobileFilterOpen] = React.useState(false);

    const view = React.useMemo(() => {
        if (isHomePage(location)) {
            return "home";
        } else if (isModelPage(location)) {
            return "models";
        } else {
            return "vehicles";
        }
    }, [location]);

    const setFilter = <K extends keyof IFilter>(key: K, value: IFilter[K] | null, shouldNavigate: boolean = true) => {
        setFilters([{ key, value }], shouldNavigate);
    };

    const setFilters = <K extends keyof IFilter>(filterValues: Array<{ key: K; value: IFilter[K] | null }>, shouldNavigate: boolean = true) => {
        const newFilter = { ...internalFilter };
        let resetPaging = true;

        for (const { value, key } of filterValues) {
            // Set Filter in internalFilter state and navigate to new url

            if (value !== undefined && value !== null) {
                newFilter[key] = value;
            } else {
                const defaultFilter = getDefaultFilter(getConfig("scope").domain);
                newFilter[key] = defaultFilter[key];
            }
            if (key === "page") {
                resetPaging = false;
            }
        }

        //reset Page
        if (resetPaging) {
            newFilter.page = 1;
        }

        setInternalFilter(newFilter);

        if (shouldNavigate) {
            navigate(generateUrlFromFilter(newFilter, view));
        }

        if (filterValues.some(({ key }) => isFinancingRateRelevantFilter(key))) {
            pushToDataLayer({
                event: "leaseme-financingrate-calculate",
                financingType:
                    newFilter.financeType === GQLFinanceType.credit
                        ? "Credit"
                        : newFilter.financeType === GQLFinanceType.leasing
                        ? "Leasing"
                        : newFilter.financeType === GQLFinanceType.openEndLeasing
                        ? "OpenEndLeasing"
                        : "ClosedEndLeasing",
            });
        }
    };

    const setSize = (newSize: number | null) => {
        if (internalFilter.size > 1 && newSize) {
            setFilter("size", newSize);
            window.scrollTo(0, 0);
        }
    };
    const setPage = (newPage: number) => {
        if (newPage !== internalFilter.page) {
            setFilter("page", newPage);
            window.scrollTo(0, 0);
        }
    };

    const previousPage = () => {
        if (internalFilter.page > 1) {
            setFilter("page", internalFilter.page - 1);
            window.scrollTo(0, 0);
        }
    };

    const nextPage = () => {
        setFilter("page", internalFilter.page + 1);
        window.scrollTo(0, 0);
    };

    const setSort = (value: ISort | null) => {
        setFilters([
            { key: "page", value: 1 },
            { key: "sort", value: value ? value.sort : DefaultSort.sort },
            { key: "sortOrder", value: value ? value.sortOrder : DefaultSort.sortOrder },
        ]);
    };

    const resetAllOptionalFilters = () => {
        const resetFilters: { key: keyof IFilter; value: string | number | string[] | null | undefined }[] = [];

        usedOptionalFilter.forEach((useFilter) => resetFilters.push({ key: useFilter.filter, value: null }));

        setFilters(resetFilters);
    };

    const resetOptionalFilter = (optionalFilter: IOptionalFilter) => {
        const filterOptions = internalFilter[optionalFilter.filter];

        if (Array.isArray(filterOptions) && filterOptions.length > 1) {
            setFilter(
                optionalFilter.filter,
                filterOptions.filter((option) => option !== optionalFilter.value),
            );
        } else {
            setFilter(optionalFilter.filter, null);
        }
    };

    const resetFilter = () => {
        const defaultFilter = getDefaultFilter(getConfig("scope").domain);
        navigate(generateUrlFromFilter(defaultFilter, view));
    };

    const generateUrlFromFilter = (filter: IFilter, view: "vehicles" | "models" | "home") => {
        const url = generateUrl(location, filter, view);
        const path = view === "vehicles" ? url.split("?")[0].slice(1, -1) : url.split("?")[0].slice(3, -1);
        if (checkIfLandingPageExists(path, allSchemeUrls?.allSchemeUrls ?? [])) {
            return url;
        }
        return generateUrl(location, filter, view, false);
    };

    return (
        <SearchFilterContext.Provider
            value={{
                filter: internalFilter,
                setFilter,
                setFilters,
                setSize,
                setPage,
                previousPage,
                nextPage,
                setSort,
                resetOptionalFilter,
                resetAllOptionalFilters,
                usedOptionalFilter,
                resetFilter,
                generateUrlFromFilter,
                mobileFilterOpen,
                setMobileFilterOpen,
            }}
        >
            {children}
        </SearchFilterContext.Provider>
    );
};

function isFinancingRateRelevantFilter(key: keyof IFilter): boolean {
    return (
        key === "maximumRate" ||
        key === "financeType" ||
        key === "financeRuntime" ||
        key === "kmPerYear" ||
        key === "downPayment" ||
        key === "remainingCredit"
    );
}

export default SearchFilterProvider;
