import { getConfig } from "@src/config";
import { GQLFinanceType, GQLSortOrder, GQLVehicleSort } from "@src/graphql.apollo.generated";

import { DefaultSort } from "../sort/ISort";

export interface IFilter {
    page: number;
    size: number;
    // Finance Data
    maximumRate: number;
    financeType: GQLFinanceType;
    financeRuntime: number;
    kmPerYear: number;
    downPayment: number;
    remainingCredit?: number;

    // Vehicle Data
    brands?: string[];
    modelGroups?: string[];
    colors?: string[];

    minKm?: number;
    maxKm?: number;
    minFirstRegistration?: number;
    maxFirstRegistration?: number;

    minPs?: number;
    maxPs?: number;

    bodyTypes?: string[];
    fuelTypes?: string[];
    provinces?: string[];

    //Navigation Data
    navigationUrl?: string;

    //Sort Data
    sort?: GQLVehicleSort;
    sortOrder?: GQLSortOrder;

    carType?: string;
}

export function getDefaultFilter(domain: string): IFilter {
    switch (domain) {
        case "car4me.bg":
            return DefaultFilterBG;
        case "car4me.ro":
            return DefaultFilterRO;
        case "car4me.si":
            return DefaultFilterSI;
        case "car4me.porschefinance.hu":
            return DefaultFilterHU;
        default:
            return DefaultFilterAT;
    }
}

export const DefaultFilterAT: IFilter = {
    page: 1,
    size: 12,
    maximumRate: 299,
    financeType: GQLFinanceType.leasing,
    financeRuntime: 60,
    kmPerYear: 15000,
    downPayment: 5000,
    remainingCredit: 20,
    carType: getConfig("isCarhubActive") && getConfig("scope").domain === "lease-me.porschebank.at" ? "usedCar" : undefined,
    ...DefaultSort,
};

export const DefaultFilterBG: IFilter = {
    page: 1,
    size: 12,
    maximumRate: 550,
    financeType: GQLFinanceType.leasing,
    financeRuntime: 48,
    kmPerYear: 25000,
    downPayment: 8000,
    ...DefaultSort,
};

export const DefaultFilterRO: IFilter = {
    page: 1,
    size: 12,
    maximumRate: 250,
    financeType: GQLFinanceType.leasing,
    financeRuntime: 48,
    kmPerYear: 25000,
    downPayment: 7000,
    remainingCredit: 20,
    ...DefaultSort,
};

export const DefaultFilterSI: IFilter = {
    page: 1,
    size: 12,
    maximumRate: 200,
    financeType: GQLFinanceType.leasing,
    financeRuntime: 84,
    kmPerYear: 1,
    downPayment: 5000,
    remainingCredit: 20,
    ...DefaultSort,
    sortOrder: GQLSortOrder.ASC,
};

export const DefaultFilterHU: IFilter = {
    page: 1,
    size: 12,
    maximumRate: 100000,
    financeType: GQLFinanceType.openEndLeasing,
    financeRuntime: 48,
    kmPerYear: 1,
    downPayment: 2000000,
    remainingCredit: 20,
    ...DefaultSort,
};

export type OptionalFilter =
    | "brands"
    | "modelGroups"
    | "colors"
    | "minKm"
    | "maxKm"
    | "minFirstRegistration"
    | "maxFirstRegistration"
    | "minPs"
    | "maxPs"
    | "bodyTypes"
    | "fuelTypes"
    | "provinces";

export const optionalFilters = [
    "brands",
    "modelGroups",
    "colors",
    "minKm",
    "maxKm",
    "minFirstRegistration",
    "maxFirstRegistration",
    "minPs",
    "maxPs",
    "bodyTypes",
    "fuelTypes",
    "provinces",
];
export interface IOptionalFilter {
    filter: OptionalFilter;
    value: string;
}
