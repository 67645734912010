import { GtmEvent } from "./types";

declare global {
    interface Window {
        dataLayer: object[] | undefined;
    }
}

const pushToDataLayer = (event: GtmEvent) => {
    const dataLayer = window.dataLayer || [];

    dataLayer.push(event);
};

export default pushToDataLayer;
