import { WindowLocation } from "@reach/router";

import isVehicleDetailPage from "./shared/utils/isVehicleDetailPage";

export const shouldUpdateScroll = ({ routerProps: { location } }: { routerProps: { location: WindowLocation }; getSavedScrollPosition: any }) => {
    if (isVehicleDetailPage(location)) {
        return true;
    }
    return false;
};
