import { getConfig } from "@src/config";
import * as React from "react";

import { ISort } from "../sort/ISort";
import { getDefaultFilter, IFilter, IOptionalFilter } from "./IFilter";

export enum SearchFilter {
    FinanceType,
    FinanceRuntime,
    KmPerYear,
}

export interface ISearchFilterContext {
    filter: IFilter;
    setFilter?: <K extends keyof IFilter>(key: K, value: IFilter[K] | null, shouldNavigate?: boolean) => void;
    setFilters?: <K extends keyof IFilter>(filterValues: Array<{ key: K; value: IFilter[K] | null }>, shouldNavigate?: boolean) => void;
    nextPage?: () => void;
    previousPage?: () => void;
    setSize?: (newSize: number | null) => void;
    setPage?: (newSize: number) => void;
    setSort?: (value: ISort | null) => void;
    usedOptionalFilter?: IOptionalFilter[];
    resetOptionalFilter?: (optionalFilter: IOptionalFilter) => void;
    resetAllOptionalFilters?: () => void;
    resetFilter?: () => void;
    generateUrlFromFilter: (filter: IFilter, view: "vehicles" | "models" | "home") => string;
    mobileFilterOpen: boolean;
    setMobileFilterOpen: (isFilterOpen: boolean) => void;
}

const SearchFilterContext = React.createContext<ISearchFilterContext>({
    filter: { ...getDefaultFilter(getConfig("scope").domain) },
    generateUrlFromFilter: () => {
        throw new Error("Missing implementation");
    },
    mobileFilterOpen: false,
    setMobileFilterOpen: () => {
        throw new Error("Missing implementation");
    },
});
export { SearchFilterContext };
