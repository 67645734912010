import { ITheme, ThemeName } from "./types";

const theme: ITheme = {
    name: ThemeName.PorscheBank,
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    fonts: {
        roboto: "kwfUp-roboto, sans-serif",
        markW1G: "kwfUp-ff-mark, sans-serif",
    },
    colors: {
        black: "#000000",
        gray1: "#222222",
        gray2: "#333333",
        gray3: "#909090",
        gray4: "#c8c8c8",
        gray5: "#dedede",
        gray6: "#f4f4f4",
        white: "#ffffff",
        borderGray: "#efefef",
        blackWithAlpha: "#000000e6",
        porscheBankBlue: "#103344",
        porscheBankRed: "#AB0440",
        darkRed: "#E03239",
        backgroundLightGray: "#EDEEF2",
        borderLightGray: "#D2D5DB",
        typo: "#2e2e2e",
        lightGray: "#f7f7f7",
        gray: "#e6e6e6",
        iconGray: "#999999",
        facebook: "#3b5998",
        twitter: "#00ACEE",
        whatsApp: "#25D366",
        viber: "#7360f2",
        error: "#801B0E",
        success: "#46800D",
        info: "#A9A9A9",
    },
    breakpoints: {
        tn: {
            mediaquery: "@media all and (min-width: 320px)",
            value: 320,
        },
        sm: {
            mediaquery: "@media all and (min-width: 375px)",
            value: 375,
        },
        md: {
            mediaquery: "@media all and (min-width: 768px)",
            value: 768,
        },
        lg: {
            mediaquery: "@media all and (min-width: 1024px)",
            value: 1024,
        },
        lg2: {
            mediaquery: "@media all and (min-width: 1150px)",
            value: 1150,
        },
        xl: {
            mediaquery: "@media all and (min-width: 1440px)",
            value: 1440,
        },
        xxl: {
            mediaquery: "@media all and (min-width: 1920px)",
            value: 1920,
        },
    },
    spacings: {
        spacing050: 5,
        spacing100: 10,
        spacing200: 20,
        spacing400: 40,
        spacing600: 60,
    },
};
export default theme;
