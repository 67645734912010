import { gql } from "@apollo/client";

export const VehicleSearchResultQuery = gql`
    query VehicleSearchResults(
        $page: Int!
        $size: Int!
        $maximumRate: Float
        $financeType: FinanceType
        $financeRuntime: Int
        $downPayment: Float
        $yearlyMileage: Int
        $brands: [String!]
        $modelGroups: [String!]
        $colors: [String!]
        $minMileage: Int
        $maxMileage: Int
        $minFirstRegistration: Int
        $maxFirstRegistration: Int
        $minPS: Int
        $maxPS: Int
        $bodyTypes: [String!]
        $fuelTypes: [String!]
        $provinces: [String!]
        $sort: VehicleSort
        $sortOrder: SortOrder
        $leasemeScope: ContentScopeInput!
        $carType: String
        $remainingCredit: Int
    ) {
        vehicles(
            page: $page
            size: $size
            maximumRate: $maximumRate
            financeType: $financeType
            financeRuntime: $financeRuntime
            yearlyMileage: $yearlyMileage
            downPayment: $downPayment
            brands: $brands
            modelGroups: $modelGroups
            colors: $colors
            mileage: { min: $minMileage, max: $maxMileage }
            firstRegistrationYear: { min: $minFirstRegistration, max: $maxFirstRegistration }
            powerPS: { min: $minPS, max: $maxPS }
            bodyTypes: $bodyTypes
            fuelTypes: $fuelTypes
            provinces: $provinces
            sort: $sort
            sortOrder: $sortOrder
            scope: $leasemeScope
            carType: $carType
            remainingCredit: $remainingCredit
        ) {
            totalCount
            totalPages
            nodes {
                ...VehicleSearchResultVehicle
            }
            facets {
                ...Facets
            }
        }
    }
    fragment VehicleSearchResultVehicle on RawVehicle {
        vehicleId
        type
        brand
        modelGroup
        model
        mileage
        firstRegistration
        powerPS
        vehicleLink
        location {
            province
        }
        fuel {
            text
        }
        images {
            uri
        }
        dealer {
            city
            zip
            name
        }
        rate(
            financeType: $financeType
            financeRuntime: $financeRuntime
            downPayment: $downPayment
            yearlyMileage: $yearlyMileage
            residualCreditInPercent: $remainingCredit
        )
        downPayment(
            financeType: $financeType
            financeRuntime: $financeRuntime
            downPayment: $downPayment
            yearlyMileage: $yearlyMileage
            residualCreditInPercent: $remainingCredit
        )
    }

    fragment Facets on VehicleFacetsType {
        mileage {
            min
            max
        }
        brands {
            name
            slug
            count
        }
        firstRegistrationYear {
            min
            max
        }
        modelGroups {
            name
            slug
            count
            image
            brand
            brandSlug
            powerPSRange {
                min
                max
            }
            fuelTypes {
                name
            }
            provinces {
                name
            }
            firstRegistrationRange {
                min
                max
            }
            mileageRange {
                min
                max
            }
            rateRange {
                min
                max
            }
        }
        fuelTypes {
            name
            slug
            count
        }
        provinces {
            name
            slug
            count
        }
        powerPS {
            max
            min
        }
        driveTypes {
            name
            slug
            count
        }
        colors {
            name
            slug
            count
        }
        bodyTypes {
            name
            slug
            count
        }
        downPayment {
            min
            max
        }
    }
`;
