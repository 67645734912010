import Favicon from "@src/shared/assets/images/favicon.ico";
import * as React from "react";
import Helmet from "react-helmet";

const FavIcon: React.FunctionComponent = () => {
    return (
        <Helmet>
            <link rel="shortcut icon" href={Favicon} type="image/ico" />
        </Helmet>
    );
};

export default FavIcon;
